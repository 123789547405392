import { useAppDispatch } from "app/hooks"
import { clearCredentials, logoutFn } from "helpers/authHelpers"
import { setUser } from "features/auth/authSlice"

const useLogut = () => {
  const dispatch = useAppDispatch()

  const logout = () => {
    logoutFn(dispatch)
  }

  return logout
}

export default useLogut
