import { FC, memo, ReactElement } from "react"

interface IProps {
  disabled: boolean
}

const RightIcon: FC<IProps> = memo(
  ({ disabled }): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M5.5 12H19.5M19.5 12L12.5 5M19.5 12L12.5 19"
        stroke={!disabled ? "#fff" : "#98A2B3"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
)

export default RightIcon
