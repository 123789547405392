import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import {
  getShippingInfoFields,
  shippingInfoYupSchema,
} from "features/checkout/steps/forms/ShippingInfoForm"
import FormFieldGroup from "components/forms/FormFieldGroup"
import Button from "components/buttons/Button"
import { CheckoutData } from "features/checkout/CheckoutSteps"

interface Props {
  onSubmit: (data: object) => void
  checkoutData: CheckoutData
}

const ReviewShippingInfo: React.FC<Props> = ({ onSubmit, checkoutData }) => {
  const [isChange, setIsChange] = useState(false)

  const { control, setValue, handleSubmit, reset } = useForm<any>({
    resolver: yupResolver(shippingInfoYupSchema),
    defaultValues: checkoutData.shippingInfo,
  })

  if (isChange) {
    return (
      <div>
        <div className="flex justify-between items-center mb-6">
          <div className="text-lg font-semibold">Shipping information</div>
        </div>
        <form>
          <FormFieldGroup
            fields={getShippingInfoFields(setValue)}
            control={control}
            columns={2}
          />

          <div className="border-t border-gray-300 my-5"></div>

          <div className="mt-5 pb-spacing-xl flex flex-col sm:flex-row gap-spacing-xl items-center sm:space-x-4 justify-end self-stretch shrink-0 relative">
            <div>
              <Button
                size="s"
                color="white"
                onClick={() => {
                  setIsChange(false)
                  reset(checkoutData.shippingInfo)
                }}
              >
                Cancel
              </Button>
            </div>
            <div>
              <Button
                size="s"
                onClick={handleSubmit((data: object) => {
                  onSubmit(data)
                  setIsChange(false)
                })}
              >
                Save changes
              </Button>
            </div>
          </div>
        </form>
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-row gap-6 justify-between w-full">
        <div className="text-lg font-semibold flex-1">Shipping information</div>
        <div className="flex items-center">
          <button
            className="text-md font-semibold text-gray-500"
            onClick={() => {
              setIsChange(true)
            }}
          >
            Change
          </button>
        </div>
      </div>
      <div className="flex flex-col gap-4 w-full">
        <div className="flex flex-col gap-2 w-full">
          <div className="text-sm text-gray-500">Name</div>
          <div className="bg-white rounded-md p-1 w-full">
            <div className="text-md">
              {checkoutData.shippingInfo.firstName}{" "}
              {checkoutData.shippingInfo.lastName}
              <br />
              {checkoutData.shippingInfo.streetAddress},{" "}
              {checkoutData.shippingInfo.apt}
              <br />
              {checkoutData.shippingInfo.city},{" "}
              {checkoutData.shippingInfo.state}{" "}
              {checkoutData.shippingInfo.zipCode}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReviewShippingInfo
