import Table, { TableData } from "components/tables/Table"
import { FC, useMemo, useState } from "react"
import Button from "components/buttons/Button"
import ManageSubscriptionPopup from "features/account/subscriptions/ManageSubscriptionPopup"
import { ReactComponent as AlertRect } from "assets/images/alert-rect.svg"
import { useGetUserSubscriptionsQuery } from "app/services/userSubscriptionsApi"
import { getFrequency } from "helpers/subscriptonHelpers"
import { DATE_FORMAT_FULL_MONTH, formatDate } from "utils/dateTimeUtils"
import { formatCurrency } from "utils/currencyUtils"
import { UserSubscription } from "types/entities/userSubscriptionTypes"
import { getMyId } from "helpers/authHelpers"
import {
  activeEnrollmentStatuses,
  inactiveEnrollmentStatuses,
} from "constants/userSubscriptionConstants"
import { SubscriptionType } from "types/entities/subscriptionTypes"
import Alert from "components/notifications/Alert"
import { isPaymentFailed } from "helpers/userSubscriptonHelpers"
import { useGetTransactionsQuery } from "app/services/transactionsApi"

interface Props {
  type?: SubscriptionType
  isActive?: boolean
}

const UserSubscriptionList: FC<Props> = ({
  type = SubscriptionType.SUBSCRIPTION,
  isActive = true,
}) => {
  const { data, isLoading } = useGetUserSubscriptionsQuery({
    user: getMyId(),
    enrollment_status: isActive
      ? activeEnrollmentStatuses
      : inactiveEnrollmentStatuses,
    "subscription.sub_type": type,
  })
  const [sub, setSub] = useState<UserSubscription>()
  const { data: transactions, isLoading: isTransactionsLoading } =
    useGetTransactionsQuery({
      user: getMyId(),
      "order[timestamp]": "desc",
    })

  const tableData = useMemo(() => {
    const result: TableData = []
    if (data && transactions) {
      data.items.forEach((sub) => {
        const cells = []
        if (isActive) {
          cells.push({
            node: isPaymentFailed(sub.subscription.id, transactions.items) ? (
              <>
                <div className="max-md:hidden" title="Payment failed">
                  <AlertRect />
                </div>
                <Alert className="md:hidden">Payment failed</Alert>
              </>
            ) : null,
          })
        }
        cells.push({
          node: sub.subscription.title,
        })
        if (isActive) {
          cells.push({
            node: getFrequency(sub.subscription),
            className: "whitespace-nowrap",
          })
        }
        cells.push({
          node: formatDate(
            isActive ? sub.next_payment_date : sub.end_date,
            DATE_FORMAT_FULL_MONTH,
          ),
        })
        cells.push({ node: formatCurrency(sub.subscription.billing_cost) })
        cells.push({
          node: (
            <div className="md:flex md:justify-end">
              <Button size="s" fullWidth={false} onClick={() => setSub(sub)}>
                {isActive ? "Manage" : "View"}
              </Button>
            </div>
          ),
        })
        result.push(cells)
      })
    }
    return result
  }, [data, transactions])

  const head = isActive
    ? ["", "Name", "Frequency", "Next Payment", "Price", ""]
    : ["Name", "Date canceled", "Price", ""]

  return (
    <>
      {sub && (
        <ManageSubscriptionPopup sub={sub} onClose={() => setSub(undefined)} />
      )}
      <Table
        isLoading={isLoading || isTransactionsLoading}
        title={getTableTitle(isActive, type)}
        head={head}
        data={tableData}
        emptyText={getTableEmptyText(isActive, type)}
      />
    </>
  )
}

const getTableTitle = (isACtive: boolean, type: SubscriptionType) => {
  let result = isACtive ? "Active" : "Inactive"
  result += " "
  result +=
    type === SubscriptionType.SUBSCRIPTION ? "subscriptions" : "memberships"

  return result
}

const getTableEmptyText = (isACtive: boolean, type: SubscriptionType) => {
  let result = "You have no "
  result += isACtive ? "active" : "inactive"
  result += " "
  result +=
    type === SubscriptionType.SUBSCRIPTION ? "subscriptions" : "memberships"

  return result
}

export default UserSubscriptionList
