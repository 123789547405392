import { FormFieldI } from "types/core/formTypes"
import { ObjectShape } from "yup"
import * as yup from "yup"

export const buildYupSchema = (fields: FormFieldI[]) => {
  return yup.object(buildYupObject(fields)).required()
}

export const buildYupObject = (fields: FormFieldI[]): ObjectShape => {
  const result: ObjectShape = {}
  fields.forEach((field) => {
    if (field.rules) {
      result[field.name] = field.rules
    }
  })
  return result
}

export const filterFormValues = (
  fields: FormFieldI[],
  values: Record<string, any>,
) => {
  let result: Record<string, any> = {}

  fields.forEach((field) => {
    const prop = field.name
    result[prop] = values[prop as keyof typeof values]
  })

  return result
}
