import BaseLayout from "layouts/BaseLayout"
import AccountDetails from "features/account/update/AccountDetails"
import AccountCommunication from "features/account/update/AccountCommunication"
import { useGetAccountQuery } from "app/services"

const AccountInformation = () => {
  const { data: account } = useGetAccountQuery()

  return (
    <BaseLayout title="Account Information" maxWidth="max-w-xl">
      <AccountDetails account={account} />
      <AccountCommunication account={account} />
    </BaseLayout>
  )
}

export default AccountInformation
