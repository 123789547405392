import { FC, ReactNode } from "react"
import Box from "components/blocks/Box"
import { ReactComponent as AlertSvg } from "assets/images/alert-circle.svg"
import { twMerge } from "tailwind-merge"

interface Props {
  children: ReactNode
  className?: string
}

const Alert: FC<Props> = ({ children, className }) => {
  return (
    <Box className={twMerge("text-center p-4", className)}>
      <div>
        <AlertSvg className="inline-block" />
      </div>
      <div className="mt-3">{children}</div>
    </Box>
  )
}

export default Alert
