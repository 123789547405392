import { FC, useState } from "react"
import Tabs from "components/tabs/Tabs"
import ManageSubscriptionDetails from "features/account/subscriptions/ManageSubscriptionDetails"
import ManageSubscriptionHistory from "features/account/subscriptions/ManageSubscriptionHistory"
import { UserSubscription } from "types/entities/userSubscriptionTypes"

interface Props {
  sub: UserSubscription
  onUpdatePaymentClick: () => void
  onCancelClick: () => void
  onDisableAutoPayClick: () => void
}

const ManageSubscriptionMainBlock: FC<Props> = ({
  sub,
  onUpdatePaymentClick,
  onCancelClick,
  onDisableAutoPayClick,
}) => {
  const [active, setActive] = useState("Details")

  return (
    <>
      <div className="text-gray-slate">{sub.subscription.title}</div>
      <Tabs
        items={["Details", "History"]}
        active={active}
        onChange={setActive}
        className="mt-8 mb-10"
      />
      {active === "Details" ? (
        <ManageSubscriptionDetails
          sub={sub}
          onUpdatePaymentClick={onUpdatePaymentClick}
          onCancelClick={onCancelClick}
          onDisableAutoPayClick={onDisableAutoPayClick}
        />
      ) : (
        <ManageSubscriptionHistory sub={sub} />
      )}
    </>
  )
}

export default ManageSubscriptionMainBlock
