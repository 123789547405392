import SubscriptionSummary from "features/checkout/steps/SubscriptionSummary"
import React, { useState, useEffect, Dispatch, SetStateAction, FC } from "react"
import AccountInfo from "features/checkout/steps/AccountInfo"
import ShippingInfo from "features/checkout/steps/ShippingInfo"
import PaymentInfo from "features/checkout/steps/PaymentInfo"
import Review from "features/checkout/steps/Review"
import ProgressBar from "features/checkout/ProgressBar"
import {
  CheckoutData,
  CheckoutDataKey,
  CheckoutStep,
  DefaultCheckoutData,
} from "features/checkout/CheckoutSteps"
import { useLocation, useNavigate } from "react-router-dom"
import { Subscription } from "types/entities/subscriptionTypes"
import { useCheckoutSubscriptionMutation } from "app/services/subscriptionApi"
import Confirmation from "features/checkout/steps/Confirmation"
import useAuth from "hooks/auth/useAuth"
import { UserSubscription } from "types/entities/userSubscriptionTypes"
import { Logo } from "types/entities/physicianLogo"

interface Props {
  setLogo: Dispatch<SetStateAction<Logo | null>>
}

const Checkout: FC<Props> = ({ setLogo }) => {
  const navigate = useNavigate()
  const user = useAuth()

  // Hooks must be called unconditionally at the top level
  const location = useLocation()
  const subscription: Subscription = location.state?.subscription

  const [currentStep, setCurrentStep] = useState<CheckoutStep>(
    CheckoutStep.SUMMARY,
  )
  const [checkoutData, setCheckoutData] =
    useState<CheckoutData>(DefaultCheckoutData)
  const [checkoutSubscription] = useCheckoutSubscriptionMutation()

  const [userSubscription, setUserSubscription] =
    useState<UserSubscription | null>(null)

  const logoUrl = subscription.physician.logo ?? null
  useEffect(() => {
    if (logoUrl) {
      setLogo(logoUrl)
    }
  }, [logoUrl, setLogo])
  useEffect(() => {
    if (!user) {
      navigate("/", { replace: true })
    }
  }, [user, navigate])

  if (!subscription) {
    return null
  }

  const handleCheckoutData = (step: CheckoutStep, data: object) => {
    const stepData: CheckoutDataKey = {}
    stepData[step] = data

    setCheckoutData((prevData) => ({
      ...prevData,
      ...stepData,
    }))
  }

  const onCheckoutSubmit = (userSubscription: UserSubscription) => {
    setUserSubscription(userSubscription)
    setCurrentStep(CheckoutStep.CONFIRMATION)
  }

  const renderStep = () => {
    switch (currentStep) {
      case CheckoutStep.SUMMARY:
        return (
          <SubscriptionSummary
            subscription={subscription}
            onSubmit={() => setCurrentStep(CheckoutStep.ACCOUNT_INFO)}
          />
        )
      case CheckoutStep.ACCOUNT_INFO:
        return (
          <AccountInfo
            onSubmit={(data) => {
              handleCheckoutData(CheckoutStep.ACCOUNT_INFO, data)
              setCurrentStep(CheckoutStep.SHIPPING_INFO)
            }}
          />
        )
      case CheckoutStep.SHIPPING_INFO:
        return (
          <ShippingInfo
            onSubmit={(data) => {
              handleCheckoutData(CheckoutStep.SHIPPING_INFO, data)
              setCurrentStep(CheckoutStep.PAYMENT_INFO)
            }}
          />
        )
      case CheckoutStep.PAYMENT_INFO:
        return (
          <PaymentInfo
            onSubmit={(data) => {
              handleCheckoutData(CheckoutStep.PAYMENT_INFO, data)
              setCurrentStep(CheckoutStep.REVIEW)
            }}
            checkoutData={checkoutData}
          />
        )
      case CheckoutStep.REVIEW:
        return (
          <Review
            subscription={subscription}
            onSubmit={onCheckoutSubmit}
            initialCheckoutData={checkoutData}
          />
        )
      case CheckoutStep.CONFIRMATION:
        return (
          <Confirmation
            subscription={subscription}
            userSubscription={userSubscription}
          />
        )
      default:
        return null
    }
  }

  const progressBarSteps = [
    {
      label: "Account",
      isActive: currentStep === CheckoutStep.ACCOUNT_INFO,
      isCompleted:
        currentStep !== CheckoutStep.SUMMARY &&
        currentStep !== CheckoutStep.ACCOUNT_INFO,
    },
    {
      label: "Shipping",
      isActive: currentStep === CheckoutStep.SHIPPING_INFO,
      isCompleted: [CheckoutStep.PAYMENT_INFO, CheckoutStep.REVIEW].includes(
        currentStep,
      ),
    },
    {
      label: "Payment",
      isActive: currentStep === CheckoutStep.PAYMENT_INFO,
      isCompleted: currentStep === CheckoutStep.REVIEW,
    },
    {
      label: "Review",
      isActive: currentStep === CheckoutStep.REVIEW,
      isCompleted: false,
    },
  ]

  const renderProgressBar = () => {
    const hasActiveStep = progressBarSteps.some((step) => step.isActive)

    return hasActiveStep && <ProgressBar steps={progressBarSteps} />
  }

  return (
    <div className="flex flex-col gap-8 w-full">
      <div className="flex flex-col gap-6 items-center w-full">
        <div className="flex flex-col gap-6 items-center w-full max-w-screen-lg">
          {renderProgressBar()}
          {renderStep()}
        </div>
      </div>
    </div>
  )
}

export default Checkout
