import Title from "components/text/Title"
import TextButton from "components/buttons/TextButton"
import TextField from "components/fields/TextField"
import { Dispatch, FC, SetStateAction } from "react"
import BooleanField from "components/fields/BooleanField"
import { User } from "types/entities/userTypes"

interface Props {
  setEdit: Dispatch<SetStateAction<boolean>>
  account: User
}

const AccountCommunicationView: FC<Props> = ({ setEdit, account }) => {
  return (
    <>
      <div className="flex justify-between">
        <Title>Communication</Title>
        <div>
          <TextButton icon={"edit"} onClick={() => setEdit(true)}>
            Edit
          </TextButton>
        </div>
      </div>
      <TextField name="Mobile">{account.phone}</TextField>
      <div className="mt-8 font-bold text-jacarta text-base">
        Mobile preferences
      </div>
      <BooleanField
        checked={account.mobile_marketing_enabled}
        name="It’s okay to send me marketing texts"
      />
      <BooleanField
        checked={account.mobile_care_enabled}
        name="It’s okay to send me texts related to my care"
      />
      <TextField name="Email">{account.email}</TextField>
      <div className="mt-8 font-bold text-jacarta text-base">
        Email preferences
      </div>
      <BooleanField
        checked={account.email_marketing_enabled}
        name="It’s okay to send me marketing emails"
      />
      <BooleanField
        checked={account.email_care_enabled}
        name="It’s okay to send me emails related to my care"
      />
    </>
  )
}

export default AccountCommunicationView
