import { Navigate, Outlet, useLocation } from "react-router-dom"
import useAuth from "hooks/auth/useAuth"

const ProtectedRoutes = () => {
  const user = useAuth()
  const location = useLocation()

  return user ? (
    <Outlet />
  ) : (
    <Navigate
      to="/login"
      state={{ redirectAfterLogin: location.pathname }}
      replace
    />
  )
}

export default ProtectedRoutes
