import { ButtonHTMLAttributes, FC, ReactNode } from "react"

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
  size?: "xs" | "s" | "m" | "l" | "xl"
  family?: "primary" | "secondary" | "header" | "body"
  weight?: "black" | "bold" | "medium" | "light" | "normal" | "semibold"
  className?: string
}

const sizeClasses: Record<string, string> = {
  xs: "text-lg",
  s: "text-3xl",
  m: "text-4xl",
  l: "text-5xl",
  xl: "text-6xl text-center mb-12",
}

const familyClasses: Record<string, string> = {
  primary: "font-primary",
  secondary: "font-secondary",
  header: "font-header",
  body: "font-body",
}

const weightClasses: Record<string, string> = {
  black: "font-black",
  bold: "font-bold",
  medium: "font-medium",
  light: "font-light",
  normal: "font-normal",
  semibold: "font-semibold",
}

const Title: FC<Props> = ({
  children,
  size = "l",
  family = "secondary",
  weight = "normal",
  className,
}) => {
  const sizeClass = sizeClasses[size]
  const familyClass = familyClasses[family]
  const weightClass = weightClasses[weight]

  return (
    <div className={`${familyClass} ${weightClass} ${sizeClass} ${className}`}>
      {children}
    </div>
  )
}

export default Title
