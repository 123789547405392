import { FC, ReactElement } from "react"

const InputAlertCircle: FC = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    className="absolute right-3 top-3"
  >
    <g id="alert-circle">
      <path
        id="Icon"
        d="M8.49992 5.33334V8M8.49992 10.6667H8.50659M15.1666 8C15.1666 11.6819 12.1818 14.6667 8.49992 14.6667C4.81802 14.6667 1.83325 11.6819 1.83325 8C1.83325 4.3181 4.81802 1.33334 8.49992 1.33334C12.1818 1.33334 15.1666 4.3181 15.1666 8Z"
        stroke="#F04438"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

export default InputAlertCircle
