import { FC, ReactNode } from "react"

interface Props {
  children?: ReactNode
}

const InputError: FC<Props> = ({ children }) => {
  if (!children) return null

  return <div className="mt-1 text-red text-sm">{children}</div>
}

export default InputError
