import { api } from "app/services/api"
import {
  PaymentInstrument,
  PaymentInstrumentResponse,
  UserIdentity,
  UserIdentityResponse,
} from "types/entities/finixTypes"

export const finixApi = api.injectEndpoints({
  endpoints: (build) => {
    return {
      createFinixPaymentInstrument: build.mutation<
        PaymentInstrumentResponse,
        PaymentInstrument
      >({
        query: (body) => ({
          url: "finix/payment-instrument",
          method: "POST",
          headers: {
            "content-type": "application/ld+json",
          },
          body: JSON.stringify(body),
        }),
      }),
      setFinixUserIdentity: build.mutation<UserIdentityResponse, UserIdentity>({
        query: (body) => ({
          url: "users/finix-identity",
          method: "PUT",
          headers: {
            "content-type": "application/ld+json",
          },
          body: JSON.stringify(body),
        }),
        invalidatesTags: ["Account"],
      }),
    }
  },
})

export const {
  useCreateFinixPaymentInstrumentMutation,
  useSetFinixUserIdentityMutation,
} = finixApi
