import React, { FC, useMemo, useState } from "react"
import { useGetUserAddressesQuery } from "app/services/userAdressApi"
import { getMyId } from "helpers/authHelpers"
import AccountAddressesEmptyView from "features/account/addresses/AccountAddressesEmptyView"
import AccountAddressList from "features/account/addresses/AccountAddressList"
import AddressCardLoader from "features/account/addresses/AddressCardLoader"

interface Props {}

const AccountAddress: FC<Props> = ({}) => {
  const { data: addressesResponse, isLoading } = useGetUserAddressesQuery({
    user: getMyId(),
  })

  const [create, setCreate] = useState(false)

  const addresses = useMemo(
    () => addressesResponse?.items ?? [],
    [addressesResponse],
  )

  if (isLoading) {
    return <AddressCardLoader />
  }

  const isEmpty = addresses.length === 0

  return (
    <>
      {isEmpty ? (
        <AccountAddressesEmptyView create={create} setCreate={setCreate} />
      ) : (
        <AccountAddressList
          addresses={addresses}
          create={create}
          setCreate={setCreate}
        />
      )}
    </>
  )
}

export default AccountAddress
