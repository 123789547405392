import { forwardRef, InputHTMLAttributes } from "react"
import { BaseFieldProps } from "types/core/formTypes"
import InputLabel from "components/inputs/InputLabel"
import InputError from "components/inputs/InputError"
import InputAlertCircle from "assets/images/InputAlertCircle"

interface Props extends BaseFieldProps, InputHTMLAttributes<HTMLInputElement> {
  inputSize?: "s" | "m" | "l"
}

const sizeClasses: Record<string, string> = {
  s: "text-base",
  m: "text-base",
  l: "text-3xl",
}

const TextInput = forwardRef<HTMLInputElement, Props>(
  ({ label, error, inputSize = "m", className, ...inputProps }, ref) => {
    const errorClass = error
      ? "border-red"
      : "border-silver focus:border-jacarta"
    const sizeClass = sizeClasses[inputSize]

    return (
      <div className="relative">
        <InputLabel>{label}</InputLabel>
        <input
          className={`
            block w-full ${inputSize === "s" ? "p-2.5" : "p-4"} leading-none border focus:outline-none
            placeholder-auroMetalSaurus font-body ${errorClass} ${sizeClass} ${className}
        `}
          {...inputProps}
          ref={ref}
        />
        {error ? <InputAlertCircle /> : null}
        <InputError>{error}</InputError>
      </div>
    )
  },
)

export default TextInput
