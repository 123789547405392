import { Dispatch, FC, SetStateAction, useState } from "react"
import AddressCard from "features/account/addresses/AddressCard"
import { UserAddress } from "types/entities/userAddress"
import AddNewAddressCard from "features/account/addresses/AddNewAddressCard"
import AccountAddressCreate from "features/account/addresses/AccountAddressCreate"
import AccountAddressEdit from "features/account/addresses/AccountAddressEdit"

interface Props {
  addresses: UserAddress[]
  create: boolean
  setCreate: Dispatch<SetStateAction<boolean>>
}

const AccountAddressList: FC<Props> = ({ addresses, create, setCreate }) => {
  const [edit, setEdit] = useState(false)
  const [selectedAddress, setSelectedAddress] = useState<UserAddress | null>(
    null,
  )

  const renderContent = () => {
    const content = {
      list: (
        <div className="grid grid-cols-2 md:grid-cols-2 gap-6">
          {addresses.map((address) => (
            <AddressCard
              key={address.id}
              address={address}
              setEdit={setEdit}
              setSelectedAddress={setSelectedAddress}
            />
          ))}
          <AddNewAddressCard onAddNew={() => setCreate(true)} />
        </div>
      ),
      create: <AccountAddressCreate setCreate={setCreate} />,
      edit: selectedAddress ? (
        <AccountAddressEdit address={selectedAddress} setEdit={setEdit} />
      ) : null,
    }

    if (create) return content.create
    if (edit) return content.edit
    return content.list
  }

  return <>{renderContent()}</>
}

export default AccountAddressList
