import BaseLayout from "layouts/BaseLayout"
import AccountAddress from "features/account/addresses/AccountAddress"

const AccountAddresses = () => {
  return (
    <BaseLayout title="Addresses">
      <AccountAddress />
    </BaseLayout>
  )
}

export default AccountAddresses
