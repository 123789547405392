import React from "react"

interface InfoBoxProps {
  text: string
  SvgIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  className?: string
}

const InfoBox: React.FC<InfoBoxProps> = ({ text, SvgIcon, className }) => {
  return (
    <div
      className={`flex items-center p-4 border rounded-md bg-gray-50 ${className}`}
    >
      <SvgIcon className="h-6 w-6 text-gray-500 mr-2" />
      <span className="text-gray-slate text-sm">{text}</span>
    </div>
  )
}

export default InfoBox
