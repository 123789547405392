import { useAppDispatch, useAppSelector } from "app/hooks"
import {
  hideSignInPopup,
  selectSignInPopup,
  showSignInPopup,
  setRedirectAfterLogin,
  RedirectAfterLogin,
} from "features/auth/authSlice"

const useSignInPopup = () => {
  const dispatch = useAppDispatch()
  const isSignInOpen = useAppSelector(selectSignInPopup)

  const showSignIn = (redirectAfterLogin?: RedirectAfterLogin) => {
    if (redirectAfterLogin) {
      dispatch(setRedirectAfterLogin(redirectAfterLogin))
    }

    dispatch(showSignInPopup())
  }

  const hideSignIn = () => dispatch(hideSignInPopup())

  return { showSignIn, hideSignIn, isSignInOpen }
}

export default useSignInPopup
