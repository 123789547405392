import { ButtonHTMLAttributes, FC, ReactNode, ReactElement } from "react"

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
  color?: "black" | "white" | "disabled"
  size?: "s" | "m" | "l"
  fullWidth?: boolean
  icon?: ReactElement | null
}

const colorClasses: Record<string, string> = {
  black: "text-white bg-black cursor-pointer border-2 border-black",
  white: "text-black bg-white cursor-pointer border-2 border-black",
  disabled:
    "bg-[#F2F4F7] text-[#98A2B3] cursor-not-allowed shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] border border-[#E4E7EC] cursor-not-allowed",
}

const sizeClasses: Record<string, string> = {
  s: "font-medium px-10 py-3 text-base",
  m: "font-medium px-10 py-4 text-lg",
  l: "font-medium px-10 py-5 text-2xl",
}

const Button: FC<Props> = ({
  children,
  color = "black",
  size = "m",
  fullWidth = true,
  type = "button",
  disabled = false,
  icon,
  ...buttonProps
}) => {
  const colorClass = colorClasses[disabled ? "disabled" : color]
  const sizeClass = sizeClasses[size]
  const fullWidthClass = fullWidth ? "w-full" : ""

  return (
    <button
      type={type}
      className={`whitespace-nowrap
      rounded-[0.625rem] px-[1.375rem] py-[1rem] font-semibold text-[1.125rem] leading-[1.75rem] inline-flex 
      items-center justify-center gap-[0.62rem] transition-colors duration-200 ease-linear w-full
      border-white/12 focus:outline-none
      ${colorClass} ${sizeClass} ${fullWidthClass}`}
      disabled={disabled}
      {...buttonProps}
    >
      {children} {icon}
    </button>
  )
}

export default Button
