import { FC, InputHTMLAttributes } from "react"
import { BaseFieldProps } from "types/core/formTypes"

interface Props extends BaseFieldProps, InputHTMLAttributes<HTMLInputElement> {
  styling?: "classic" | "modern"
}

const CheckboxInput: FC<Props> = ({
  styling = "modern",
  label,
  error,
  ...inputProps
}) => {
  let inputClass = ""
  let labelClass = ""

  switch (styling) {
    case "classic":
      inputClass +=
        " h-6 w-6 border border-gray-cadet rounded-lg checked:border-black checked:bg-black bg-checkIconWhite"
      break
    case "modern":
      inputClass += " h-7 w-7 bg-unchecked checked:bg-checked"
      labelClass += " font-semibold"
      break
  }

  return (
    <div className="relative cursor-pointer">
      <label className="flex items-center select-none">
        <input
          type="checkbox"
          className={`block shrink-0 appearance-none cursor-pointer bg-center bg-no-repeat ${inputClass}`}
          {...inputProps}
          checked={Boolean(inputProps.value)}
        />
        {label && (
          <span className={`block pl-2 cursor-pointer ${labelClass}`}>
            {label}
          </span>
        )}
      </label>
    </div>
  )
}

export default CheckboxInput
