import { ReactComponent as LogoSvg } from "assets/images/logo.svg"
import { ReactComponent as LogoutSvg } from "assets/images/logout.svg"
import { ReactComponent as ProfileSvg } from "assets/images/profile.svg"
import SignInPopup from "features/auth/SignInPopup"
import useLogout from "hooks/auth/useLogout"
import useAuth from "hooks/auth/useAuth"
import { Link } from "react-router-dom"
import { Logo } from "types/entities/physicianLogo"
import { FC } from "react"

interface Props {
  customLogo?: Logo | null
}
const BaseHeader: FC<Props> = ({ customLogo }) => {
  const user = useAuth()
  const logout = useLogout()

  return (
    <div className="shadow">
      <header className="container mx-auto px-4 py-4">
        <div className="flex justify-between items-center">
          <Link to="/">
            {customLogo ? (
              <img
                src={customLogo.large}
                alt="Custom Logo"
                className="w-auto h-11"
              />
            ) : (
              <LogoSvg />
            )}
          </Link>
          <div className="cursor-pointer">
            {user ? (
              <div className="grid grid-cols-2 gap-x-4">
                <div>
                  <Link to="/account">
                    <ProfileSvg />
                  </Link>
                </div>
                <div>
                  <LogoutSvg onClick={() => logout()} />
                </div>
              </div>
            ) : (
              <div>
                <Link to="/login">
                  <ProfileSvg />
                </Link>
              </div>
            )}
          </div>
        </div>
      </header>
      <SignInPopup />
    </div>
  )
}

export default BaseHeader
