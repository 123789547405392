import BaseLayout from "layouts/BaseLayout"
import OrderList from "features/account/orders/OrderList"

const AccountOrders = () => {
  return (
    <BaseLayout title="Purchase History">
      <OrderList />
    </BaseLayout>
  )
}

export default AccountOrders
