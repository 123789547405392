import { buildRest } from "app/services/restEndpoints"
import { api } from "app/services/api"
import { UserSubscription } from "types/entities/userSubscriptionTypes"

export const userSubscriptionsApi = api.injectEndpoints({
  endpoints: (build) => {
    const rest = buildRest<UserSubscription>(
      build,
      "user-subscriptions",
      "UserSubscriptions",
    )
    return {
      getUserSubscription: rest.one,
      getUserSubscriptions: rest.list,
      updateUserSubscription: rest.update,
    }
  },
})

export const {
  useGetUserSubscriptionQuery,
  useGetUserSubscriptionsQuery,
  useUpdateUserSubscriptionMutation,
} = userSubscriptionsApi
