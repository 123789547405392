import { Dispatch, FC, SetStateAction, useState } from "react"
import Text from "components/text/Text"
import Button from "components/buttons/Button"
import AccountAddressCreate from "features/account/addresses/AccountAddressCreate"

interface Props {
  create: boolean
  setCreate: Dispatch<SetStateAction<boolean>>
}

const AccountAddressesEmptyView: FC<Props> = ({ create, setCreate }) => {
  const handleButtonClick = () => {
    setCreate(true)
  }

  return (
    <>
      {!create ? (
        <div className="flex flex-col items-center justify-center h-full py-10">
          <Text size="l" className="mb-4">
            You have no addresses.
          </Text>
          <Button size="s" fullWidth={false} onClick={handleButtonClick}>
            Add address
          </Button>
        </div>
      ) : (
        <AccountAddressCreate setCreate={setCreate} />
      )}
    </>
  )
}

export default AccountAddressesEmptyView
