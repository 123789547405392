import { FC, useEffect } from "react"
import Box from "components/blocks/Box"
import { ReactComponent as AlertSvg } from "assets/images/alert-circle.svg"
import Button from "components/buttons/Button"
import TextButton from "components/buttons/TextButton"
import {
  EnrollmentStatus,
  UserSubscription,
} from "types/entities/userSubscriptionTypes"
import { useUpdateUserSubscriptionMutation } from "app/services/userSubscriptionsApi"
import { getTypeName } from "helpers/subscriptonHelpers"

interface Props {
  sub: UserSubscription
  onDisable: () => void
  onClose: () => void
}

const ManageSubscriptionDisableAutoPayBlock: FC<Props> = ({
  sub,
  onDisable,
  onClose,
}) => {
  const [update, { isLoading, isSuccess }] = useUpdateUserSubscriptionMutation()

  useEffect(() => {
    if (isSuccess) {
      onDisable()
    }
  }, [isSuccess])

  const handleChange = () => {
    update({
      id: sub.id,
      enrollment_status: EnrollmentStatus.ACTIVE_NORENEW,
    })
  }

  return (
    <>
      <Box className="mt-8">
        <div className="text-center">
          <AlertSvg className="mb-8 inline-block" />
        </div>
        <div className="mb-4">
          You have selected to disable auto-pay for this{" "}
          {getTypeName(sub.subscription.sub_type)}.
        </div>
        <div className="mb-4">Please note:</div>
        <ul className="mb-4 list-disc list-inside pl-4">
          <li>
            Disabling auto-pay does not cancel your{" "}
            {getTypeName(sub.subscription.sub_type)}
          </li>
          <li>
            You will continue to be charged until your current{" "}
            {getTypeName(sub.subscription.sub_type)}
            period ends
          </li>
          <li>
            To avoid future charges, remember to cancel your
            {getTypeName(sub.subscription.sub_type)} before the renewal date
          </li>
        </ul>
        <div className="text-center">
          <div className="mt-10">
            <Button
              size="s"
              fullWidth={false}
              disabled={isLoading}
              onClick={handleChange}
            >
              Disabling auto-pay
            </Button>
          </div>
          <div className="mt-6">
            <TextButton onClick={onClose} disabled={isLoading}>
              Cancel
            </TextButton>
          </div>
        </div>
      </Box>
    </>
  )
}

export default ManageSubscriptionDisableAutoPayBlock
