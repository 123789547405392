import BaseLayout from "layouts/BaseLayout"
import PaymentMethodEdit from "features/account/payment-methods/PaymentMethodEdit"

const AccountPaymentMethodCreate = () => {
  return (
    <BaseLayout title="Payment methods" maxWidth="max-w-xl">
      <PaymentMethodEdit isNew={true} />
    </BaseLayout>
  )
}

export default AccountPaymentMethodCreate
