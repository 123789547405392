import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormFieldType } from "constants/core/formConstants"
import { buildYupSchema, filterFormValues } from "helpers/core/formHelpers"
import FormField from "components/forms/FormField"
import { User } from "types/entities/userTypes"
import TextButton from "components/buttons/TextButton"
import WellnessCodePopup from "features/account/update/WellnessCodePopup"
import { FC } from "react"
import useUpdateByWellnessCode from "features/account/update/useUpdateByWellnessCode"
import { WellnessCodeType } from "constants/authConstants"
import { EMAIL_RULES, PHONE_RULES } from "constants/validationConstants"

const fields = {
  email: {
    type: FormFieldType.TEXT,
    name: "email",
    label: "Email",
    placeholder: "Email",
    rules: EMAIL_RULES.required(),
  },
  phone: {
    type: FormFieldType.TEXT,
    name: "phone",
    label: "Mobile",
    placeholder: "Mobile",
    rules: PHONE_RULES.required(),
  },
}

const wellnessTypes = {
  email: WellnessCodeType.EMAIL,
  phone: WellnessCodeType.PHONE,
}

const submitButtonTexts = {
  email: "Update email address",
  phone: "Update mobile number",
}

interface Props {
  name: "phone" | "email"
  account: User
}

const AccountWellnessCodeInput: FC<Props> = ({ name, account }) => {
  const field = fields[name]
  const wellnessType = wellnessTypes[name]
  const {
    sendCode,
    closePopup,
    isPopupOpen,
    update,
    contact,
    isLoading,
    updateError,
  } = useUpdateByWellnessCode({ name })
  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm<any>({
    resolver: yupResolver(buildYupSchema([field])),
    values: filterFormValues([field], account),
  })

  const onSubmit = (data: User) => {
    sendCode({ type: wellnessType, value: data[name] })
  }

  return (
    <>
      <FormField field={field} control={control} />
      {isDirty && (
        <div className="py-3">
          <TextButton disabled={isLoading} onClick={handleSubmit(onSubmit)}>
            Update
          </TextButton>
        </div>
      )}
      <WellnessCodePopup
        onClose={closePopup}
        contact={String(contact)}
        isOpen={isPopupOpen}
        onSubmit={update}
        isLoading={isLoading}
        error={updateError}
        submitButtonText={submitButtonTexts[name]}
      />
    </>
  )
}

export default AccountWellnessCodeInput
