import React from "react"
import { ReactComponent as CheckIcon } from "assets/images/check-icon.svg"

interface Step {
  label: string
  isActive: boolean
  isCompleted: boolean
}

interface ProgressBarProps {
  steps: Step[]
}

const ProgressBar: React.FC<ProgressBarProps> = ({ steps }) => {
  return (
    <div className="flex items-center justify-between w-full mb-5">
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          <div className="flex flex-col items-center">
            <div className="relative flex items-center justify-center w-8 h-8">
              {step.isCompleted ? (
                <div className="bg-black text-white rounded-full flex items-center justify-center w-8 h-8">
                  <CheckIcon className="w-4 h-4" />
                </div>
              ) : (
                <div
                  className={`w-8 h-8 rounded-full border-2 flex items-center justify-center ${
                    step.isActive ? "border-black" : "border-gray-300"
                  }`}
                >
                  <div
                    className={`w-4 h-4 rounded-full ${
                      step.isActive ? "bg-black" : "bg-gray-300"
                    }`}
                  />
                </div>
              )}
            </div>
            <div className="ml-2">
              <span
                className={`${
                  step.isActive || step.isCompleted
                    ? "text-black font-medium"
                    : "text-gray-500"
                } absolute -ml-[30px]`}
              >
                {step.label}
              </span>
            </div>
          </div>

          {index < steps.length - 1 && (
            <div
              className="flex-auto border-t-2"
              style={{
                borderColor:
                  steps[index + 1].isCompleted || steps[index + 1].isActive
                    ? "#262422"
                    : "#E4E7EC",
                borderWidth: "2px",
              }}
            ></div> /* Horizontal line */
          )}
        </React.Fragment>
      ))}
    </div>
  )
}

export default ProgressBar
