import { EnrollmentStatus } from "types/entities/userSubscriptionTypes"

export const activeEnrollmentStatuses = [
  EnrollmentStatus.ACTIVE,
  EnrollmentStatus.ACTIVE_NORENEW,
  EnrollmentStatus.ACTIVE_GRANDFATHERED,
]

export const inactiveEnrollmentStatuses = [
  EnrollmentStatus.INACTIVE,
  EnrollmentStatus.CANCELLED,
]
