import { FC, ReactNode } from "react"
import { twMerge } from "tailwind-merge"
import { TableCell, TableData } from "components/tables/Table"

interface Props {
  head: string[]
  data: TableData
  isLoading: boolean
}

const renderCell = (cell: TableCell) => {
  return (
    <td
      className={twMerge(
        "py-8 border-b last:pr-0 border-silver-light",
        cell.node && "pr-6",
        cell?.className,
      )}
    >
      {cell.node}
    </td>
  )
}

const TableDesktop: FC<Props> = ({ head, data, isLoading }) => {
  return (
    <table className="max-md:hidden table-auto w-full">
      <thead>
        <tr>
          {head.map((name, index) => (
            <th
              key={index}
              className={twMerge(
                "py-8 last:pr-0 text-left border-b border-black whitespace-nowrap",
                name && "pr-6",
              )}
            >
              {name}
            </th>
          ))}
        </tr>
      </thead>
      {!isLoading && data && (
        <tbody>
          {data.map((row, i1) => (
            <tr key={i1}>{row.map((cell, i2) => renderCell(cell))}</tr>
          ))}
        </tbody>
      )}
    </table>
  )
}

export default TableDesktop
