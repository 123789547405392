import React, { forwardRef } from "react"
import DatePicker, { DatePickerProps } from "react-date-picker"
import { BaseFieldProps } from "types/core/formTypes"
import InputLabel from "components/inputs/InputLabel"
import InputError from "components/inputs/InputError"
import "react-date-picker/dist/DatePicker.css"
import "react-calendar/dist/Calendar.css"
import "components/inputs/DateInput.css"

interface Props extends BaseFieldProps, DatePickerProps {}

const DateInput = forwardRef<HTMLInputElement, Props>(
  ({ label, error, ...inputProps }, ref) => {
    return (
      <div ref={ref}>
        <InputLabel>{label}</InputLabel>
        <DatePicker
          format="MM/dd/yyyy"
          calendarIcon={null}
          clearIcon={null}
          {...inputProps}
        />
        <InputError>{error}</InputError>
      </div>
    )
  },
)

export default DateInput
