export const getAddressComponent = (
  addressComponents: google.maps.GeocoderAddressComponent[],
  type: string,
  long: boolean = true,
) => {
  let component = addressComponents.find((component) =>
    component.types.includes(type),
  )

  if (long) {
    return component?.long_name || ""
  } else {
    return component?.short_name || ""
  }
}
