import { FC, ReactNode, HTMLAttributes } from "react"

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
  color?: "grey" | "black"
  size?: "s" | "m" | "l" | "xl"
  weight?: "black" | "bold" | "medium" | "light" | "normal" | "semibold"
  family?: "primary" | "secondary" | "header" | "body"
  className?: string
}

const colorClasses: Record<string, string> = {
  grey: "text-gray-charcoal",
  black: "text-black-primary",
}

const sizeClasses: Record<string, string> = {
  s: "text-sm",
  m: "text-base",
  l: "text-lg",
  xl: "text-xl",
}

const weightClasses: Record<string, string> = {
  black: "font-black",
  bold: "font-bold",
  medium: "font-medium",
  light: "font-light",
  normal: "font-normal",
  semibold: "font-semibold",
}

const familyClasses: Record<string, string> = {
  primary: "font-primary",
  secondary: "font-secondary",
  header: "font-header",
  body: "font-body",
}

const Text: FC<Props> = ({
  children,
  color = "black",
  size = "m",
  weight = "normal",
  family = "body",
  className,
}) => {
  const colorClass = colorClasses[color]
  const sizeClass = sizeClasses[size]
  const weightClass = weightClasses[weight]
  const familyClass = familyClasses[family]

  return (
    <div
      className={`${familyClass} ${colorClass} ${sizeClass} ${weightClass} ${className}`}
    >
      {children}
    </div>
  )
}

export default Text
