import React, { FC } from "react"
import FinixPaymentForm, {
  FinixOnSuccess,
} from "features/finix/FinixPaymentForm"
import { UserSubscription } from "types/entities/userSubscriptionTypes"
import Button from "components/buttons/Button"
import { useUpdatePaymentMutation } from "app/services"
import { useGetTransactionsQuery } from "app/services/transactionsApi"
import { getMyId } from "helpers/authHelpers"
import { getLastTransaction } from "helpers/userSubscriptonHelpers"
import { isTransactionFailed } from "helpers/transactionsHelpers"
import PaymentError from "features/payment/PaymentError"

interface Props {
  sub: UserSubscription
  onUpdate: () => void
  onClose: () => void
}

const ManageSubscriptionPaymentBlock: FC<Props> = ({
  sub,
  onUpdate,
  onClose,
}) => {
  const [updatePayment, { isLoading, error }] = useUpdatePaymentMutation()
  const { data: transactions } = useGetTransactionsQuery({
    user: getMyId(),
    "order[timestamp]": "desc",
  })

  const handleSuccess: FinixOnSuccess = async ({
    paymentInstrument,
    fraudSessionId,
    idempotencyId,
  }) => {
    if (!transactions) {
      return
    }
    const failedTransaction = getLastTransaction(
      sub.subscription.id,
      transactions.items,
    )
    if (!failedTransaction || !isTransactionFailed(failedTransaction)) {
      console.error("Failed transaction is not found")
      return
    }

    const updated = await updatePayment({
      paymentInfo: {
        paymentInstrumentId: paymentInstrument.id,
        fraudSessionId: fraudSessionId,
        idempotencyId: idempotencyId,
      },
      transactionCorrelationId: failedTransaction.id.toString(),
    })
    if (updated?.data?.id) {
      onUpdate()
    }
  }

  return (
    <div className="mt-8">
      <div className="mb-8">
        Please update your payment information to reactivate your subscription.
      </div>
      <PaymentError mutationError={error} />
      <FinixPaymentForm
        onSuccess={handleSuccess}
        buttonLabel="Update payment & process"
        isDisabled={isLoading}
      />
      <div className="mt-5">
        <Button size="s" color="white" onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>
      </div>
    </div>
  )
}

export default ManageSubscriptionPaymentBlock
