import { FormFieldI } from "types/core/formTypes"
import { buildYupSchema } from "helpers/core/formHelpers"
import { FormFieldType } from "constants/core/formConstants"
import * as yup from "yup"
import { UseFormSetValue } from "react-hook-form/dist/types/form"
import { stateOptions } from "constants/core/stateConstants"

export const addressFields = (
  setValue?: UseFormSetValue<any>,
): FormFieldI[] => [
  {
    type: FormFieldType.TEXT,
    name: "nameFirst",
    label: "First name",
    placeholder: "First name",
    rules: yup.string().required("First name is a required field"),
  },
  {
    type: FormFieldType.TEXT,
    name: "nameLast",
    label: "Last name",
    placeholder: "Last name",
    rules: yup.string().required("Last name is a required field"),
  },
  {
    type: FormFieldType.GOOGLE_PLACES,
    name: "quickAddressSearch",
    label: "Address 1",
    placeholder: "Address 1",
    onChange: (place: google.maps.places.PlaceResult) => {
      if (!setValue || !place?.address_components) {
        return
      }

      const { address_components: addressComponents } = place

      const getAddressComponent = (type: string, long: boolean = true) =>
        addressComponents.find((component) => component.types.includes(type))?.[
          long ? "long_name" : "short_name"
        ] ?? ""

      const updateFieldValue = (field: string, value: string) => {
        if (value) {
          setValue(field, value, { shouldDirty: true })
        }
      }

      updateFieldValue(
        "addressCity",
        getAddressComponent("locality") ||
          getAddressComponent("administrative_area_level_2"),
      )
      updateFieldValue(
        "addressRegion",
        getAddressComponent("administrative_area_level_1", false),
      )
      updateFieldValue("addressPostalCode", getAddressComponent("postal_code"))

      const addressLine1Value =
        getAddressComponent("street_number") +
        " " +
        getAddressComponent("route")
      updateFieldValue("addressLine1", addressLine1Value.trim())
    },
  },
  {
    type: FormFieldType.TEXT,
    name: "addressLine1",
    label: "Address 1",
    placeholder: "Address 1",
    rules: yup.string().required("Address 1 is a required field"),
  },
  {
    type: FormFieldType.TEXT,
    name: "addressLine2",
    label: "Address 2",
    placeholder: "Address 2",
    rules: yup.string().notRequired(),
  },
  {
    type: FormFieldType.TEXT,
    name: "addressCity",
    label: "City",
    placeholder: "City",
    rules: yup.string().required("City is a required field"),
  },
  {
    type: FormFieldType.TEXT,
    name: "addressRegion",
    label: "State",
    placeholder: "State",
    options: stateOptions,
    rules: yup
      .string()
      .length(2, "State must be exactly 2 characters")
      .required("State is a required field"),
  },
  {
    type: FormFieldType.NUMBER,
    name: "addressPostalCode",
    label: "Postal code",
    placeholder: "Postal code",
    rules: yup
      .string()
      .required("Postal code is a required field")
      .matches(
        /^\d{5}(-\d{4})?$/,
        "Postal code must be in the format 12345 or 12345-6789",
      ),
  },
]

export const addressYupSchema = buildYupSchema(addressFields())
