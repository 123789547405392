import FormFieldGroup from "components/forms/FormFieldGroup"
import {
  detailsFields,
  detailsYupSchema,
} from "features/account/update/accountForms"
import FormButtons from "components/forms/FormButtons"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { User } from "types/entities/userTypes"
import { useUpdateAccountMutation } from "app/services/accountApi"
import Title from "components/text/Title"
import { Dispatch, FC, SetStateAction, useEffect } from "react"
import { filterFormValues } from "helpers/core/formHelpers"

interface Props {
  setEdit: Dispatch<SetStateAction<boolean>>
  account: User
}

const AccountDetailsEdit: FC<Props> = ({ setEdit, account }) => {
  const [updateAccount, { isLoading: isUpdating, isSuccess }] =
    useUpdateAccountMutation()

  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm<any>({
    resolver: yupResolver(detailsYupSchema),
    values: filterFormValues(detailsFields, account),
  })

  const onSubmit = (data: User) => {
    updateAccount(data)
  }

  useEffect(() => {
    if (isSuccess) {
      setEdit(false)
    }
  }, [isSuccess])

  return (
    <>
      <Title>Details</Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormFieldGroup fields={detailsFields} control={control} />
        <FormButtons
          onCancel={() => setEdit(false)}
          isSubmitDisabled={!isDirty || isUpdating}
        />
      </form>
    </>
  )
}

export default AccountDetailsEdit
