import { FormatedQuery, QueryParameters } from "types/urlTypes"

export const formatQuery = (params?: QueryParameters): FormatedQuery => {
  if (!params) return {}

  const result: FormatedQuery = {}

  for (const param in params) {
    const value = params[param]

    if (Array.isArray(value)) {
      let i = 0
      value.forEach((valueItem) => {
        result[`${param}[${i}]`] = valueItem
        i++
      })
    } else {
      result[param] = value
    }
  }

  return result
}
