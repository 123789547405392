import React from "react"

interface CheckoutContainerProps {
  children: React.ReactNode
}

const CheckoutContainer: React.FC<CheckoutContainerProps> = ({ children }) => {
  return (
    <div className="flex flex-col gap-6 w-full shadow-lg">
      <div className="bg-white rounded-md border border-gray-200 p-8 w-full">
        <div className="flex flex-col gap-6 w-full">{children}</div>
      </div>
    </div>
  )
}

export default CheckoutContainer
