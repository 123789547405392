import React, { FC, InputHTMLAttributes, memo, ReactElement } from "react"
import { BaseFieldProps } from "types/core/formTypes"
import InputAlertCircle from "assets/images/InputAlertCircle"
import InputError from "components/inputs/InputError"
import InputLabel from "components/inputs/InputLabel"

interface Props extends BaseFieldProps, InputHTMLAttributes<HTMLInputElement> {
  inputSize?: "s" | "m" | "l"
}

const sizeClasses: Record<string, string> = {
  s: "text-base",
  m: "text-base",
  l: "text-3xl",
}

const PhoneEmailInput: FC<Props> = memo(
  ({
    label,
    error,
    inputSize = "m",
    value,
    className,
    ...props
  }): ReactElement => {
    const errorClass = error
      ? "border-red"
      : "border-silver focus:border-jacarta"
    const sizeClass = sizeClasses[inputSize]

    return (
      <div className="relative">
        <InputLabel>{label}</InputLabel>
        <input
          type="text"
          id="input-field"
          name="input-field"
          value={value}
          autoComplete="off"
          className={`
            block w-full ${inputSize === "s" ? "p-2.5" : "p-4"} leading-none border focus:outline-none
            placeholder-auroMetalSaurus font-body ${errorClass} ${sizeClass} ${className}
        `}
          {...props}
        />
        {error ? <InputAlertCircle /> : null}
        <InputError>{error}</InputError>
      </div>
    )
  },
)

export default PhoneEmailInput
