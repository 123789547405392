import { api } from "app/services/api"
import { UpdatePayment } from "types/entities/paymentTypes"
import { User } from "types/entities/userTypes"

export const paymentApi = api.injectEndpoints({
  endpoints: (build) => ({
    updatePayment: build.mutation<User, UpdatePayment>({
      query: (body) => ({
        url: "users/payment-instrument",
        method: "PUT",
        headers: {
          "content-type": "application/ld+json",
        },
        body: JSON.stringify(body),
      }),
      invalidatesTags: ["Account", "UserSubscriptions", "Transactions"],
    }),
  }),
})

export const { useUpdatePaymentMutation } = paymentApi
