import BaseLayout from "layouts/BaseLayout"
import UserSubscriptionList from "features/account/subscriptions/UserSubscriptionList"
import { SubscriptionType } from "types/entities/subscriptionTypes"

const AccountMemberships = () => {
  return (
    <BaseLayout title="Memberships">
      <UserSubscriptionList type={SubscriptionType.MEMBERSHIP} />
      <div className="h-24"></div>
      <UserSubscriptionList
        type={SubscriptionType.MEMBERSHIP}
        isActive={false}
      />
    </BaseLayout>
  )
}

export default AccountMemberships
