import { buildRest } from "app/services/restEndpoints"
import { api } from "app/services/api"
import { Transaction } from "types/entities/transactionTypes"

export const transactionsApi = api.injectEndpoints({
  endpoints: (build) => {
    const rest = buildRest<Transaction>(build, "transactions", "Transactions")
    return {
      getTransaction: rest.one,
      getTransactions: rest.list,
    }
  },
})

export const { useGetTransactionQuery, useGetTransactionsQuery } =
  transactionsApi
