import { EndpointBuilder } from "@reduxjs/toolkit/query"
import { entityId } from "types/core/basicEntityTypes"
import { ListResponse, ListServerResponse } from "types/core/apiTypes"
import { QueryParameters } from "types/urlTypes"
import { formatQuery } from "helpers/urlHelpers"

export const buildRest = <T extends entityId>(
  build: EndpointBuilder<any, any, any>,
  endpoint: string,
  tag?: string,
) => {
  const tags = tag ? [tag] : undefined

  return {
    one: build.query<T, number | string>({
      query: (id) => `${endpoint}/${id}`,
      providesTags: tags,
    }),

    list: build.query<ListResponse<T>, QueryParameters | void>({
      query: (params) => ({
        url: endpoint,
        method: "GET",
        params: params ? formatQuery(params) : null,
      }),
      transformResponse: transformListResponse,
      providesTags: tags,
    }),

    create: build.mutation<T, Partial<T>>({
      query: (body) => ({
        url: endpoint,
        method: "POST",
        headers: {
          "content-type": "application/ld+json",
        },
        body: JSON.stringify(body),
      }),
      invalidatesTags: tags,
    }),

    update: build.mutation<T, Partial<T>>({
      query: ({ id, ...body }) => {
        return {
          url: `${endpoint}/${id}`,
          method: "PATCH",
          headers: {
            "content-type": "application/merge-patch+json",
          },
          body: JSON.stringify(body),
        }
      },
      invalidatesTags: tags,
    }),

    delete: build.mutation<T, number>({
      query: (id) => ({
        url: `${endpoint}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: tags,
    }),
  }
}

export const transformListResponse = <Item>(
  response: ListServerResponse<Item>,
) => {
  const result: ListResponse<Item> = {
    total: 0,
    items: [],
  }

  if (response) {
    result.items = response["hydra:member"]
    result.total = response["hydra:totalItems"]
  }

  return result
}
