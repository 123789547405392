import { Transaction, TransactionStatus } from "types/entities/transactionTypes"

export const filterBySubscriptionId = (
  subId: number | string,
  items: Transaction[],
) => {
  return items.filter((item) => item.subscriptions.includes(Number(subId)))
}

export const isTransactionFailed = (transaction?: Transaction) => {
  return transaction && transaction.ack === TransactionStatus.FAILURE
}
