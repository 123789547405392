import Alert from "components/notifications/Alert"
import {
  isErrorWithData,
  isFetchBaseQueryError,
} from "helpers/core/rtkQueryHelpers"
import React, { FC } from "react"
import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react"
import { SerializedError } from "@reduxjs/toolkit"

interface Props {
  mutationError: FetchBaseQueryError | SerializedError | undefined
}

const PaymentError: FC<Props> = ({ mutationError }) => {
  if (!mutationError) return null

  return (
    <Alert className="mt-2">
      {isFetchBaseQueryError(mutationError) && mutationError?.status === 400 ? (
        isErrorWithData(mutationError) && mutationError?.data?.detail ? (
          mutationError.data.detail
        ) : (
          <>
            <div>We're sorry, but we weren't able to process your payment.</div>
            <div>Please try again or use different card.</div>
          </>
        )
      ) : (
        <>An unexpected error occurred, please contact support team</>
      )}
    </Alert>
  )
}

export default PaymentError
