import Button from "components/buttons/Button"
import { FC } from "react"

interface Props {
  submitName?: string
  isSubmitDisabled?: boolean
  onCancel?: () => void
}

const FormButtons: FC<Props> = ({
  submitName = "Save",
  isSubmitDisabled = false,
  onCancel,
}) => {
  return (
    <div className="grid grid-cols-2 gap-6 mt-10">
      <div>
        <Button color={"white"} size="l" onClick={onCancel}>
          Cancel
        </Button>
      </div>
      <div>
        <Button type="submit" size="l" disabled={isSubmitDisabled}>
          {submitName}
        </Button>
      </div>
    </div>
  )
}

export default FormButtons
