import { parsePhoneNumberFromString } from "libphonenumber-js"

export const phoneToShortForm = (phone: string): string => {
  if (!phone) {
    return ""
  }

  return phone.replace("+1", "")
}

export function formatPhoneNumber(phoneNumber: string) {
  const parsedNumber = parsePhoneNumberFromString(phoneNumber, "US")
  if (!parsedNumber) return phoneNumber

  return parsedNumber.formatNational()
}
