import { FC } from "react"
import { ReactComponent as CheckedSvg } from "assets/images/checked.svg"
import { ReactComponent as UncheckedSvg } from "assets/images/unchecked.svg"

interface Props {
  name: string
  checked: boolean
}

const BooleanField: FC<Props> = ({ name, checked }) => {
  const Icon = checked ? CheckedSvg : UncheckedSvg

  return (
    <div className="mt-4 font-bold text-jacarta text-base">
      <Icon className="inline-block mr-1" />
      {name}
    </div>
  )
}

export default BooleanField
