import { FC } from "react"
import { twMerge } from "tailwind-merge"

interface Props {
  items: string[]
  active?: string
  onChange: (item: string) => void
  className?: string
}

const Tabs: FC<Props> = ({ items, active, onChange, className }) => {
  return (
    <div
      className={`flex justify-between rounded-lg p-1 bg-white-ghost border border-gray-bright text-center 
        font-semibold text-jacarta select-none ${className}`}
    >
      {items.map((item, i) => (
        <div
          key={i}
          className={twMerge(
            "cursor-pointer py-3 flex-grow rounded-lg",
            active === item && "bg-white shadow",
          )}
          onClick={() => onChange(item)}
        >
          {item}
        </div>
      ))}
    </div>
  )
}

export default Tabs
