import React from "react"
import ContentLoader from "react-content-loader"

const SubscriptionLoader = () => (
  <ContentLoader
    speed={2}
    width={1000}
    height={600}
    viewBox="0 0 1000 600"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    {/* Image placeholder */}
    <rect x="15" y="15" rx="10" ry="10" width="250" height="350" />

    {/* Title */}
    <rect x="300" y="20" rx="4" ry="4" width="400" height="25" />

    {/* Info Box */}
    <rect x="300" y="60" rx="4" ry="4" width="200" height="20" />
    <rect x="300" y="90" rx="4" ry="4" width="150" height="20" />

    {/* Billing */}
    <rect x="300" y="130" rx="4" ry="4" width="80" height="25" />
    <rect x="400" y="130" rx="4" ry="4" width="150" height="20" />

    {/* Button */}
    <rect x="300" y="180" rx="6" ry="6" width="200" height="40" />

    {/* Description */}
    <rect x="20" y="400" rx="4" ry="4" width="960" height="80" />
  </ContentLoader>
)

export default SubscriptionLoader
