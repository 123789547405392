import React from "react"
import Title from "components/text/Title"
import { CheckoutData } from "features/checkout/CheckoutSteps"
import PaymentForm from "features/checkout/steps/forms/PaymentForm"

interface Props {
  onSubmit: (data: object) => void
  checkoutData: CheckoutData
}

const PaymentInfo: React.FC<Props> = ({ onSubmit, checkoutData }) => {
  return (
    <div className="px-container-padding-desktop flex flex-col gap-6 items-center w-full max-w-container-max-width-desktop">
      <div className="flex flex-col gap-6 w-full max-w-3xl">
        <div className="flex flex-col gap-8 w-full">
          <div className="flex flex-row gap-4 w-full">
            <Title>Payment Information</Title>
          </div>
        </div>
        <div className="bg-primary rounded-xl border border-tertiary flex flex-col w-full shadow-lg">
          <div className="p-6 flex flex-col gap-6 w-full">
            <PaymentForm
              checkoutData={checkoutData}
              onSubmit={onSubmit}
              buttonLabel="Continue to review"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentInfo
