import { entityId } from "types/core/basicEntityTypes"

export interface Drug extends entityId {
  name: DrugType
}

export enum DrugType {
  TIRZEPATIDE = "Tirzepatide",
  SEMAGLUTIDE = "Semaglutide",
}
