import BaseLayout from "layouts/BaseLayout"
import Checkout from "features/checkout/Checkout"
import { useState } from "react"
import { Logo } from "types/entities/physicianLogo"

const SubscriptionCheckout = () => {
  const [customLogo, setCustomLogo] = useState<Logo | null>(null)

  return (
    <BaseLayout hasBreadcrumbs={false} maxWidth="m" customLogo={customLogo}>
      <Checkout setLogo={setCustomLogo} />
    </BaseLayout>
  )
}

export default SubscriptionCheckout
