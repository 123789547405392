import { ReactComponent as ProfileSvg } from "assets/images/profile.svg"
import { ReactComponent as SubscriptionSvg } from "assets/images/subscriptions.svg"
import { ReactComponent as OrdersSvg } from "assets/images/orders.svg"
import { ReactComponent as PaymentsSvg } from "assets/images/payments.svg"
import { ReactComponent as DeliverySvg } from "assets/images/delivery.svg"
import { ReactComponent as StarSvg } from "assets/images/star.svg"
import { FC } from "react"

export interface AccountMenu {
  icon: FC
  link: string
  title: string
  description: string
}

export const accountMenuItems: AccountMenu[] = [
  {
    icon: ProfileSvg,
    link: "/account/info",
    title: "Account Information",
    description: "Update your account information",
  },
  {
    icon: SubscriptionSvg,
    link: "/account/subscriptions",
    title: "Subscriptions",
    description: "Manage your active subscriptions",
  },
  {
    icon: StarSvg,
    link: "/account/memberships",
    title: "Memberships",
    description: "Discover your membership perks",
  },
  {
    icon: OrdersSvg,
    link: "/account/orders",
    title: "Purchase history",
    description: "View your order history",
  },
  {
    icon: PaymentsSvg,
    link: "/account/payment-methods",
    title: "Payment Methods",
    description: "Edit your billing and shipping address",
  },
  {
    icon: DeliverySvg,
    link: "/account/addresses",
    title: "Addresses",
    description: "Update your payment information on file",
  },
]
