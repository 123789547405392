import { FC } from "react"
import { ReactComponent as AddSvg } from "assets/images/plus.svg"
import Text from "components/text/Text"

interface Props {
  onAddNew: () => void
}

const AddNewAddressCard: FC<Props> = ({ onAddNew }) => {
  return (
    <div
      className="border rounded-lg flex flex-col justify-center items-center p-6 cursor-pointer h-46"
      onClick={onAddNew}
    >
      <div className="bg-gray-200 rounded-full p-4 mb-2">
        <AddSvg className="w-6 h-6 text-gray-500" />
      </div>
      <Text>Add new address</Text>
    </div>
  )
}

export default AddNewAddressCard
