import useSignInPopup from "features/auth/useSignInPopup"
import { useLocation, useSearchParams } from "react-router-dom"
import { useEffect } from "react"
import useAuth from "hooks/auth/useAuth"

const useAutoShowSignInPopup = () => {
  const user = useAuth()
  const { showSignIn } = useSignInPopup()
  const [params] = useSearchParams()
  const location = useLocation()

  useEffect(() => {
    if (!user && params.get("auth")) {
      showSignIn(
        location.state?.redirectAfterLogin
          ? {
              path: location.state.redirectAfterLogin,
            }
          : undefined,
      )
    }
  }, [params])
}

export default useAutoShowSignInPopup
