import Popup from "components/popups/Popup"
import { FC } from "react"
import PoweredBy from "components/blocks/PoweredBy"
import WellnessCodeForm from "features/wellnesscode/WellnessCodeForm"

interface Props {
  isOpen: boolean
  onClose: () => void
  onSubmit: (code: string) => void
  contact: string
  isLoading: boolean
  error?: string
  submitButtonText: string
}

const WellnessCodePopup: FC<Props> = ({
  isOpen = false,
  onClose,
  onSubmit,
  contact,
  isLoading,
  error,
  submitButtonText,
}) => {
  return (
    <Popup isOpen={isOpen} onClose={onClose}>
      <div className="w-[450px]">
        <WellnessCodeForm
          contact={contact}
          isDisabled={isLoading}
          onSubmit={onSubmit}
          onCancel={onClose}
          error={error}
          submitButtonText={submitButtonText}
        />
        <PoweredBy />
      </div>
    </Popup>
  )
}

export default WellnessCodePopup
