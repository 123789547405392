import useAuth from "hooks/auth/useAuth"
import { useAppDispatch } from "app/hooks"
import { getAccessToken, getMe, setMe } from "helpers/authHelpers"
import { useGetAccountQuery } from "app/services"
import useLogout from "hooks/auth/useLogout"
import { useEffect } from "react"
import { setUser } from "features/auth/authSlice"

const useRefreshAuth = () => {
  const user = useAuth()
  const dispatch = useAppDispatch()
  const storageUser = getMe()
  const logout = useLogout()

  const accessToken = getAccessToken()

  // Conditionally trigger useGetAccountQuery only if accessToken exists
  const { data, isError } = useGetAccountQuery(undefined, {
    skip: !accessToken, // Skip query if no access token
  })

  useEffect(() => {
    if (isError) {
      logout()
    }
  }, [isError, logout])

  useEffect(() => {
    if (data) {
      setMe(data)
      dispatch(setUser(data))
    }
  }, [data, dispatch])

  if (accessToken && storageUser && !user) {
    dispatch(setUser(storageUser))
  }
}

export default useRefreshAuth
