import { entityId } from "types/core/basicEntityTypes"
import { Subscription } from "types/entities/subscriptionTypes"

export interface UserSubscription extends entityId {
  subscription: Subscription
  enrolled_date: Date
  next_payment_date: Date
  enrollment_status: EnrollmentStatus
  end_date: Date
  last_payment_date: Date
  last_voucher_date: Date
  next_voucher_date: Date
  enrollment_flag: number
  period_step: number
  period_locked: boolean
  period_last_update: Date
  period_advance_date: Date
  period_notes: string
}

export enum EnrollmentStatus {
  INACTIVE = 0,
  ACTIVE = 1,
  ACTIVE_NORENEW = 2,
  ACTIVE_GRANDFATHERED = 3,
  CANCELLED = 4,
}
