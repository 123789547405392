import { ReactComponent as LogoSvg } from "assets/images/logo.svg"
import { ReactComponent as FacebookSvg } from "assets/images/facebook.svg"
import { ReactComponent as InstagramSvg } from "assets/images/instagram.svg"
import { ReactComponent as TwitterSvg } from "assets/images/twitter.svg"
import { ReactComponent as EnvelopeSvg } from "assets/images/envelope.svg"

const BaseFooter = () => {
  return (
    <footer className="bg-white-ghost mt-20">
      <div className="container mx-auto px-4 py-6">
        <div className="my-4">
          <p>
            <LogoSvg />
          </p>
          <p className="pt-4 text-gray-granite">
            Your digital prescription for a healthier life.
          </p>
        </div>
        <div className="flex justify-start space-x-10 my-8">
          <a href="http://www.facebook.com/drwellrx" target="_blank">
            <FacebookSvg />
          </a>
          <a href="http://www.instagram.com/drwellrx" target="_blank">
            <InstagramSvg />
          </a>
          <a href="http://x.com/drwellrx" target="_blank">
            <TwitterSvg />
          </a>
          <a href="mailto:feelgood@drwell.com" target="_blank">
            <EnvelopeSvg />
          </a>
        </div>
        <div className="border-t border-silver py-4">
          <p>
            &copy; {new Date().getFullYear()} BuildMyHealth™. All rights
            reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default BaseFooter
