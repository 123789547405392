import { FC } from "react"
import Box from "components/blocks/Box"
import { ReactComponent as AlertSvg } from "assets/images/alert-circle.svg"
import Button from "components/buttons/Button"
import TextField from "components/fields/TextField"
import TextButton from "components/buttons/TextButton"
import { List } from "react-content-loader"
import { useGetUserSubscriptionQuery } from "app/services/userSubscriptionsApi"
import {
  EnrollmentStatus,
  UserSubscription,
} from "types/entities/userSubscriptionTypes"
import { DATE_FORMAT_FULL_MONTH, formatDate } from "utils/dateTimeUtils"
import { getFrequency, getTypeName } from "helpers/subscriptonHelpers"
import {
  isPaymentFailed,
  isSubscriptionActive,
} from "helpers/userSubscriptonHelpers"
import Alert from "components/notifications/Alert"
import { useGetTransactionsQuery } from "app/services/transactionsApi"
import { getMyId } from "helpers/authHelpers"

interface Props {
  sub: UserSubscription
  onUpdatePaymentClick: () => void
  onCancelClick: () => void
  onDisableAutoPayClick: () => void
}

const ManageSubscriptionDetails: FC<Props> = ({
  sub,
  onUpdatePaymentClick,
  onCancelClick,
  onDisableAutoPayClick,
}) => {
  const { data: transactions } = useGetTransactionsQuery({
    user: getMyId(),
    "order[timestamp]": "desc",
  })
  const { data } = useGetUserSubscriptionQuery(sub.id)

  if (!data || !transactions) {
    return <List />
  }

  const isActive = isSubscriptionActive(data)

  return (
    <>
      {isPaymentFailed(sub.subscription.id, transactions.items) && (
        <Alert className="p-8 mb-12">
          <div className="mb-6">
            Your {getTypeName(sub.subscription.sub_type)} payment was
            unsuccessful.
          </div>
          <div className="mb-10">
            Please update your payment details to reactivate your{" "}
            {getTypeName(sub.subscription.sub_type)}.
          </div>
          <div className="flex justify-center">
            <Button fullWidth={false} onClick={onUpdatePaymentClick}>
              Update payment details
            </Button>
          </div>
        </Alert>
      )}
      <div>{data.subscription.description}</div>
      <div className="md:grid grid-cols-2 gap-x-4 mt-4">
        <div>
          <TextField name="Enrollment date">
            {formatDate(data.enrolled_date, DATE_FORMAT_FULL_MONTH)}
          </TextField>
          <TextField name="Next payment date">
            {formatDate(data.next_payment_date, DATE_FORMAT_FULL_MONTH)}
          </TextField>
        </div>
        <div>
          <TextField name="Billing frequency">
            {getFrequency(data.subscription)}
          </TextField>
          {isActive && (
            <TextField name="Auto-pay">
              {data.enrollment_status !== EnrollmentStatus.ACTIVE_NORENEW ? (
                <>
                  Enrolled in auto-pay&nbsp;
                  <span
                    className="text-blue cursor-pointer hover:underline"
                    onClick={onDisableAutoPayClick}
                  >
                    (disable auto-pay)
                  </span>
                </>
              ) : (
                "Disabled"
              )}
            </TextField>
          )}
        </div>
      </div>
      {isActive && (
        <div className="mt-8 mb-12">
          <TextButton onClick={onCancelClick}>
            Cancel {getTypeName(sub.subscription.sub_type)}
          </TextButton>
        </div>
      )}
    </>
  )
}

export default ManageSubscriptionDetails
