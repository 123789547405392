import { FC, ReactNode } from "react"
import Title from "components/text/Title"
import { Code } from "react-content-loader"
import TableDesktop from "components/tables/TableDesktop"
import TableMobile from "components/tables/TableMobile"

export type TableData = TableCell[][]

export interface TableCell {
  node: ReactNode
  className?: string
}

interface Props {
  title?: string
  head: string[]
  data: TableData
  emptyText: string
  isLoading?: boolean
}

const Table: FC<Props> = ({
  title,
  head,
  data,
  emptyText,
  isLoading = false,
}) => {
  return (
    <div className="mb-8 text-lg">
      {title && (
        <Title size="l" className="mb-10">
          {title}
        </Title>
      )}
      <TableDesktop data={data} head={head} isLoading={isLoading} />
      <TableMobile data={data} head={head} isLoading={isLoading} />
      {isLoading && <Code className="mt-12" />}
      {!isLoading && data && data.length === 0 && (
        <div className="py-8 text-center">{emptyText}</div>
      )}
    </div>
  )
}

export default Table
