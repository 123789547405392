import { FC } from "react"
import { TableData } from "components/tables/Table"

interface Props {
  head: string[]
  data: TableData
  isLoading: boolean
}

const TableMobile: FC<Props> = ({ head, data, isLoading }) => {
  return (
    <div className="md:hidden">
      {!isLoading && data && (
        <>
          {data.map((row, i1) => (
            <div className="px-2 mb-12 border-b border-silver">
              {row.map((cell, i2) => (
                <div key={i1} className="pb-6">
                  {head[i2] ? (
                    <>
                      <div className="grid grid-cols-2 gap-x-2">
                        <div className="font-semibold">{head[i2]}</div>
                        <div>{cell.node}</div>
                      </div>
                    </>
                  ) : (
                    <div>{cell.node}</div>
                  )}
                </div>
              ))}
            </div>
          ))}
        </>
      )}
    </div>
  )
}

export default TableMobile
