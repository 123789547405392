import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "app/store"
import { User } from "types/entities/userTypes"
import { NavigateOptions } from "react-router-dom"

export interface RedirectAfterLogin extends NavigateOptions {
  path: string
}

type AuthState = {
  user: User | null
  signInPopup: boolean
  redirectAfterLogin: RedirectAfterLogin | null
}

const initialState: AuthState = {
  user: null,
  signInPopup: false,
  redirectAfterLogin: null,
}

const slice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setUser: (state, { payload: user }: PayloadAction<User | null>) => {
      state.user = user

      if (state.redirectAfterLogin && user) {
        state.redirectAfterLogin = null
      }
    },
    showSignInPopup: (state) => {
      state.signInPopup = true
    },
    hideSignInPopup: (state) => {
      state.signInPopup = false
    },
    setRedirectAfterLogin: (
      state,
      { payload }: PayloadAction<RedirectAfterLogin>,
    ) => {
      state.redirectAfterLogin = payload
    },
  },
})

export const {
  setUser,
  showSignInPopup,
  hideSignInPopup,
  setRedirectAfterLogin,
} = slice.actions

export default slice.reducer

export const selectCurrentUser = (state: RootState) => state.auth.user

export const selectSignInPopup = (state: RootState) => state.auth.signInPopup

export const selectRedirectAfterLogin = (state: RootState) =>
  state.auth.redirectAfterLogin
