import { entityId } from "types/core/basicEntityTypes"
import { Subscription } from "types/entities/subscriptionTypes"
import { Physician } from "types/entities/physicianTypes"

export interface Transaction extends entityId {
  timestamp: Date
  amt: number
  ack: TransactionStatus
  subscriptions: number[]
  subscription?: Subscription
  physician: Physician
}

export enum TransactionStatus {
  SUCCESS = "Success",
  FAILURE = "Failure",
}
