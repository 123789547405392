import Table, { TableData } from "components/tables/Table"
import Button from "components/buttons/Button"
import ReceiptPopup from "features/account/orders/ReceiptPopup"
import { useMemo, useState } from "react"
import { DATE_FORMAT_FULL_MONTH, formatDate } from "utils/dateTimeUtils"
import { formatCurrency } from "utils/currencyUtils"
import { Transaction } from "types/entities/transactionTypes"
import useGetMyTransactionsWithSubscription from "hooks/transactions/useGetMyTransactionsWithSubscription"

const OrderList = () => {
  const [transaction, setTransaction] = useState<Transaction>()
  const { data, isLoading } = useGetMyTransactionsWithSubscription()

  const tableData = useMemo(() => {
    let result: TableData = []
    if (data) {
      result = data.map((item) => [
        { node: item.subscription?.title },
        {
          node: formatDate(item.timestamp, DATE_FORMAT_FULL_MONTH),
          className: "whitespace-nowrap",
        },
        { node: formatCurrency(item.amt) },
        {
          node: (
            <div className="md:flex md:justify-end">
              <Button
                size="s"
                fullWidth={false}
                onClick={() => setTransaction(item)}
              >
                View receipt
              </Button>
            </div>
          ),
        },
      ])
    }
    return result
  }, [data])

  const head = ["Name", "Purchased", "Amount", ""]

  return (
    <>
      {transaction && (
        <ReceiptPopup
          transaction={transaction}
          onClose={() => setTransaction(undefined)}
        />
      )}
      <Table
        isLoading={isLoading}
        head={head}
        data={tableData}
        emptyText="You have no purchase history."
      />
    </>
  )
}

export default OrderList
