import { FC, useMemo } from "react"
import Table, { TableData } from "components/tables/Table"
import { DATE_FORMAT_FULL_MONTH, formatDate } from "utils/dateTimeUtils"
import { formatCurrency } from "utils/currencyUtils"
import { useGetTransactionsQuery } from "app/services/transactionsApi"
import { getMyId } from "helpers/authHelpers"
import { filterBySubscriptionId } from "helpers/transactionsHelpers"
import { UserSubscription } from "types/entities/userSubscriptionTypes"

interface Props {
  sub: UserSubscription
}

const ManageSubscriptionHistory: FC<Props> = ({ sub }) => {
  const { data, isLoading } = useGetTransactionsQuery({
    user: getMyId(),
    "order[timestamp]": "desc",
  })

  const tableData = useMemo(() => {
    let result: TableData = []
    if (data) {
      result = filterBySubscriptionId(sub.subscription.id, data.items).map(
        (item) => [
          { node: formatDate(item.timestamp, DATE_FORMAT_FULL_MONTH) },
          { node: formatCurrency(item.amt) },
          { node: item.ack },
        ],
      )
    }
    return result
  }, [data])

  const head = ["Payment Date", "Amount", "Status"]

  return (
    <>
      <Table
        head={head}
        isLoading={isLoading}
        data={tableData}
        emptyText="Empty history"
      />
    </>
  )
}

export default ManageSubscriptionHistory
