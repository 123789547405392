import BaseLayout from "layouts/BaseLayout"
import PaymentMethodList from "features/account/payment-methods/PaymentMethodList"

const AccountPaymentMethods = () => {
  return (
    <BaseLayout title="Payment Methods" maxWidth="max-w-xl">
      <PaymentMethodList />
    </BaseLayout>
  )
}

export default AccountPaymentMethods
