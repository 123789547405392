import Popup from "components/popups/Popup"
import useSignInPopup from "features/auth/useSignInPopup"
import Login from "pages/Login"

const SignInPopup = () => {
  const { hideSignIn, isSignInOpen } = useSignInPopup()
  return (
    <Popup isOpen={isSignInOpen} onClose={() => hideSignIn()}>
      <Login inPopup />
    </Popup>
  )
}

export default SignInPopup
