import React from "react"
import AppRoutes from "routes/AppRoutes"
import useRefreshAuth from "hooks/auth/useRefreshAuth"

const App = () => {
  useRefreshAuth()

  return <AppRoutes />
}

export default App
