import { FC, ReactNode } from "react"
import { twMerge } from "tailwind-merge"

interface Props {
  children: ReactNode
  className?: string
}

const Box: FC<Props> = ({ children, className }) => {
  return (
    <div className={twMerge("p-8 border rounded-xl bg-gray-50", className)}>
      {children}
    </div>
  )
}

export default Box
