import React from "react"
import Button from "components/buttons/Button"
import Title from "components/text/Title"
import { Subscription } from "types/entities/subscriptionTypes"
import { getDrugImage, getFrequency } from "helpers/subscriptonHelpers"
import Image from "components/images/Image"

interface Props {
  onSubmit: () => void
  subscription: Subscription
}

const SubscriptionSummary: React.FC<Props> = ({ onSubmit, subscription }) => {
  const drugName = subscription?.drug?.name
  const drugImage = getDrugImage(drugName)

  return (
    <div className="w-full px-4">
      <div className="max-w-5xl mx-auto">
        <Title size="s">Subscription Summary</Title>
        <div className="flex flex-col gap-6 w-full shadow-lg">
          <div className="bg-white rounded-md border border-gray-200 p-8 w-full">
            <div className="flex flex-col gap-6 w-full">
              <div className="border-b border-gray-200 py-4">
                <div className="flex items-center gap-4">
                  <div className="w-24 h-32 overflow-hidden">
                    {drugImage ? (
                      <Image
                        src={drugImage}
                        alt={`${drugName} Weight Loss Program`}
                      />
                    ) : null}
                  </div>
                  <div className="flex-1 flex flex-col gap-2">
                    <div className="text-gray-900 font-semibold text-sm">
                      {subscription.title}
                    </div>
                    <div className="text-gray-700 text-sm">
                      Billed {getFrequency(subscription)?.toLowerCase()} for{" "}
                      {subscription.sub_interval} months
                    </div>
                  </div>
                  <div className="text-gray-900 font-semibold text-sm text-right">
                    ${subscription.billing_cost}
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex justify-between border-b border-gray-200 py-3">
                  <div className="text-gray-900 font-medium text-md">
                    Shipping
                  </div>
                  <div className="text-gray-900 font-semibold text-md">
                    Free
                  </div>
                </div>
                <div className="flex justify-between border-b border-gray-200 py-3">
                  <div className="text-gray-900 font-medium text-md">
                    Subtotal
                  </div>
                  <div className="text-gray-900 font-semibold text-md">
                    ${subscription.billing_cost}
                  </div>
                </div>
                <div className="flex justify-between py-3">
                  <div className="text-gray-900 font-semibold text-lg">
                    Subscription cost
                  </div>
                  <div className="text-gray-900 font-semibold text-lg">
                    ${subscription.billing_cost}
                  </div>
                </div>
              </div>
              <div className="mt-6">
                <Button size="s" onClick={onSubmit}>
                  Continue to checkout
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubscriptionSummary
