import React, { Dispatch, FC, SetStateAction } from "react"
import TextButton from "components/buttons/TextButton"
import Text from "components/text/Text"
import { UserAddress } from "types/entities/userAddress"

interface Props {
  address: UserAddress
  setEdit: Dispatch<SetStateAction<boolean>>
  setSelectedAddress: Dispatch<SetStateAction<UserAddress | null>>
}

const AddressCard: FC<Props> = ({ address, setEdit, setSelectedAddress }) => {
  const handleEdit = () => {
    setEdit(true)
    setSelectedAddress(address)
  }

  return (
    <div className="border rounded-lg p-8 w-full h-46">
      <div className="flex justify-between items-center">
        <Text size="xl" weight="semibold" family="primary">
          {address.nameFirst} {address.nameLast}
        </Text>
        <TextButton icon={"edit"} onClick={handleEdit}>
          Edit
        </TextButton>
      </div>
      <div className="mt-3">
        <Text size="l" family="primary">
          {address.addressLine1}
        </Text>
        <Text size="l" family="primary">
          {address.addressLine2}
        </Text>
        <Text size="l" family="primary">
          {address.addressCity}, {address.addressRegion},{" "}
          {address.addressPostalCode}
        </Text>
      </div>
    </div>
  )
}

export default AddressCard
