import { User } from "types/entities/userTypes"
import { api } from "app/services/api"
import { phoneToShortForm } from "utils/phoneUtils"

export const accountApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAccount: build.query<User, void>({
      query: () => ({
        url: "users/me",
      }),
      transformResponse: (data: User): User => ({
        ...data,
        phone: phoneToShortForm(data.phone),
      }),
      providesTags: ["Account"],
    }),
    getAccountByToken: build.query<User, string>({
      query: (token) => ({
        url: "users/me",
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ["Account"],
    }),
    updateAccount: build.mutation<User, Partial<User>>({
      query: ({ id, ...body }) => {
        return {
          url: "users/me",
          method: "PATCH",
          headers: {
            "content-type": "application/merge-patch+json",
          },
          body: JSON.stringify(body),
        }
      },
      invalidatesTags: ["Account"],
    }),
  }),
})

export const {
  useGetAccountQuery,
  useLazyGetAccountByTokenQuery,
  useUpdateAccountMutation,
} = accountApi
