import { FC } from "react"
import { Control } from "react-hook-form"
import { FormFieldI } from "types/core/formTypes"
import FormField from "components/forms/FormField"

interface Props {
  fields: FormFieldI[]
  control: Control
  columns?: 1 | 2 | 3 | 4
}

const columnClasses = {
  1: "",
  2: "grid-cols-2 gap-x-8",
  3: "grid-cols-3 gap-x-8",
  4: "grid-cols-4 gap-x-8",
}

const FormFieldGroup: FC<Props> = ({ fields, control, columns = 1 }) => {
  let skipNext = false
  const columnClass = columnClasses[columns]

  return (
    <div className={`md:grid ${columnClass}`}>
      {fields.map((field, index) => {
        if (skipNext) {
          skipNext = false
          return null
        }

        const item = <FormField key={index} field={field} control={control} />

        if (field.joinNext && fields[index + 1]) {
          skipNext = true
          return (
            <div
              key={`${index}-${index + 1}`}
              className="md:grid grid-cols-2 gap-x-8"
            >
              <div>{item}</div>
              <div>
                <FormField
                  key={index + 1}
                  field={fields[index + 1]}
                  control={control}
                />
              </div>
            </div>
          )
        }

        return item
      })}
    </div>
  )
}

export default FormFieldGroup
