import { FormFieldI } from "types/core/formTypes"
import { FormFieldType } from "constants/core/formConstants"
import * as yup from "yup"
import { buildYupSchema } from "helpers/core/formHelpers"
import { UseFormSetValue } from "react-hook-form/dist/types/form"
import { stateOptions } from "constants/core/stateConstants"
import { getAddressComponent } from "utils/addressUtils"

export const getShippingInfoFields = (
  setValue?: UseFormSetValue<any>,
): FormFieldI[] => [
  {
    type: FormFieldType.TEXT,
    name: "firstName",
    label: "First name",
    placeholder: "First name",
    rules: yup.string().required("First name is required"),
    joinNext: true,
  },
  {
    type: FormFieldType.TEXT,
    name: "lastName",
    label: "Last name",
    placeholder: "Last name",
    rules: yup.string().required("Last name is required"),
  },
  {
    type: FormFieldType.SELECT,
    name: "country",
    label: "Country",
    placeholder: "Country",
    rules: yup.string().required("Country is required"),
    options: [
      {
        value: "US",
        label: "United States of America",
      },
    ],
  },
  {
    type: FormFieldType.GOOGLE_PLACES,
    name: "streetAddress",
    label: "Street address",
    placeholder: "Street address",
    rules: yup.string().required("Street address is required"),
    onChange: (place: any) => {
      if (!setValue) {
        return
      }

      if (typeof place === "string") {
        setValue("streetAddress", place)
      }

      if (!place.address_components) {
        return
      }

      // Extract address components
      const addressComponents = place.address_components

      // Populate city, state, zip_code, and country
      const city =
        getAddressComponent(addressComponents, "locality") ||
        getAddressComponent(addressComponents, "administrative_area_level_2")
      const state = getAddressComponent(
        addressComponents,
        "administrative_area_level_1",
        false,
      )
      const zipCode = getAddressComponent(addressComponents, "postal_code")
      const country = getAddressComponent(addressComponents, "country", false)

      setValue("city", city)
      setValue("state", state)
      setValue("zipCode", zipCode)
      setValue("country", country)

      const streetNumber =
        getAddressComponent(addressComponents, "street_number") || ""
      const route = getAddressComponent(addressComponents, "route") || ""
      const formattedStreetAddress = `${streetNumber} ${route}`.trim()

      setValue("streetAddress", formattedStreetAddress)
    },
  },
  {
    type: FormFieldType.TEXT,
    name: "apt",
    label: "Apt. / Suite No.",
    placeholder: "Apt. / Suite No.",
    rules: yup.string().notRequired(),
  },
  {
    type: FormFieldType.TEXT,
    name: "city",
    label: "City",
    placeholder: "City",
    rules: yup.string().required("City is required"),
  },
  {
    type: FormFieldType.SELECT,
    name: "state",
    label: "State",
    placeholder: "State",
    rules: yup.string().required("State is required"),
    options: stateOptions,
    joinNext: true,
  },
  {
    type: FormFieldType.TEXT,
    name: "zipCode",
    label: "Postal code",
    placeholder: "Postal code",
    rules: yup
      .string()
      .required("Zip code is required")
      .matches(
        /^\d{5}(-\d{4})?$/,
        "Postal code must be in the format 12345 or 12345-6789",
      ),
  },
]

export const shippingInfoYupSchema = buildYupSchema(getShippingInfoFields())
