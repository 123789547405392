import React, { FC } from "react"
import ContentLoader from "react-content-loader"

const AddressCardLoader: FC = () => (
  <ContentLoader
    speed={2}
    width={300}
    height={120}
    viewBox="0 0 300 120"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="5" ry="5" width="300" height="25" />
    <rect x="0" y="40" rx="5" ry="5" width="250" height="15" />
    <rect x="0" y="60" rx="5" ry="5" width="250" height="15" />
    <rect x="0" y="80" rx="5" ry="5" width="200" height="15" />
  </ContentLoader>
)

export default AddressCardLoader
