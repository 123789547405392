import { api } from "app/services/api"
import {
  WellnessCodeLogin,
  WellnessCodeLoginResponse,
  WellnessCodeRequest,
} from "types/authTypes"
import { User } from "types/entities/userTypes"

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    generateWellnessCode: build.mutation<User, WellnessCodeRequest>({
      query: (body) => ({
        url: "users/generate-wellness-code",
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "content-type": "application/ld+json",
        },
      }),
    }),
    loginBYWellnessCode: build.mutation<
      WellnessCodeLoginResponse,
      WellnessCodeLogin
    >({
      query: (body) => ({
        url: "auth/wellness-code/login",
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "content-type": "application/ld+json",
        },
      }),
    }),
  }),
})

export const {
  useGenerateWellnessCodeMutation,
  useLoginBYWellnessCodeMutation,
} = authApi
