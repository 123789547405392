import { FC, SelectHTMLAttributes, forwardRef } from "react"
import { BaseFieldProps } from "types/core/formTypes"
import InputLabel from "components/inputs/InputLabel"
import InputError from "components/inputs/InputError"

export interface SelectOption {
  value: string
  label: string
}

interface Props
  extends BaseFieldProps,
    SelectHTMLAttributes<HTMLSelectElement> {
  options: SelectOption[]
}

const SelectInput: FC<Props> = forwardRef<HTMLSelectElement, Props>(
  ({ label, error, options = [], ...inputProps }, ref) => {
    return (
      <div>
        {label && <InputLabel>{label}</InputLabel>}
        <select
          ref={ref} // Pass the ref to the select element
          className="block w-full p-4 text-base leading-4 rounded-md border border-silver focus:outline-none
            focus:border-jacarta placeholder-silver-quick bg-white"
          {...inputProps}
        >
          <option value=""></option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        {error && <InputError>{error}</InputError>}
      </div>
    )
  },
)

SelectInput.displayName = "SelectInput"

export default SelectInput
