import BaseLayout from "layouts/BaseLayout"
import UserSubscriptionList from "features/account/subscriptions/UserSubscriptionList"

const AccountSubscriptions = () => {
  return (
    <BaseLayout title="Subscriptions">
      <UserSubscriptionList />
      <div className="h-24"></div>
      <UserSubscriptionList isActive={false} />
    </BaseLayout>
  )
}

export default AccountSubscriptions
