import Button from "components/buttons/Button"
import TextButton from "components/buttons/TextButton"
import { Link } from "react-router-dom"
import useAuth from "hooks/auth/useAuth"

const PaymentMethodList = () => {
  const user = useAuth()

  return (
    <div className="pt-8">
      {!user?.credit_authorization ? (
        <>
          <div className="text-center text-lg mb-6">
            You have no payment methods.
          </div>
          <div className="flex justify-center">
            <Link to="/account/payment-methods/create">
              <Button size="m" fullWidth={false}>
                Add payment method
              </Button>
            </Link>
          </div>
        </>
      ) : (
        <div className="flex justify-between p-8 border rounded-xl text-lg">
          <div>
            {user.credit_type} ending in {user.credit_number_last_four}
          </div>
          <div>
            <Link to="/account/payment-methods/edit">
              <TextButton icon="edit">Edit</TextButton>
            </Link>
          </div>
        </div>
      )}
    </div>
  )
}

export default PaymentMethodList
