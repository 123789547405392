import { OTPInput, SlotProps } from "input-otp"
import cn from "classnames"
import { FC } from "react"
import { twMerge } from "tailwind-merge"

interface Props {
  onChange: (value: string) => void
  className?: string
}

const OtpInput: FC<Props> = ({ onChange, className }) => {
  return (
    <OTPInput
      maxLength={6}
      onChange={onChange}
      autoFocus
      render={({ slots }) => (
        <>
          <div
            className={twMerge(
              "flex justify-center items-stretch space-x-2",
              className,
            )}
          >
            {slots.map((slot, idx) => (
              <Slot key={idx} {...slot} />
            ))}
          </div>
        </>
      )}
    />
  )
}

const Slot = (props: SlotProps) => {
  return (
    <div
      className={cn(
        "relative flex-1 text-[2rem]",
        "flex items-center justify-center",
        "transition-all duration-300",
        "border border-silver rounded-md",
        "group-hover:border-accent-foreground/20 group-focus-within:border-accent-foreground/20",
        "outline outline-0 outline-accent-foreground/20",
        { "outline-4 outline-accent-foreground": props.isActive },
      )}
    >
      {props.char !== null && <div>{props.char}</div>}
      {props.hasFakeCaret && <FakeCaret />}
    </div>
  )
}

function FakeCaret() {
  return (
    <div className="absolute pointer-events-none inset-0 flex items-center justify-center animate-caret-blink">
      <div className="w-px h-8 bg-white" />
    </div>
  )
}

export default OtpInput
