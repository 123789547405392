import { Outlet } from "react-router-dom"
import useAutoShowSignInPopup from "features/auth/useAutoShowSignInPopup"

const RootWrapper = () => {
  useAutoShowSignInPopup()

  return <Outlet />
}

export default RootWrapper
