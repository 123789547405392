export enum CheckoutStep {
  SUMMARY = "summary",
  ACCOUNT_INFO = "accountInfo",
  SHIPPING_INFO = "shippingInfo",
  PAYMENT_INFO = "paymentInfo",
  REVIEW = "review",
  CONFIRMATION = "confirmation",
}

export interface CheckoutData {
  accountInfo: {
    firstName: string
    lastName: string
    dateOfBirth: string
    gender: string
    phone: string
    email: string
  }
  shippingInfo: {
    firstName: string
    lastName: string
    country: string
    streetAddress: string
    apt: string
    city: string
    state: string
    zipCode: string
  }
  paymentInfo: CheckoutPaymentInfo
}

export interface CheckoutPaymentInfo {
  finixToken: string
  last4: string
  cardType: string
  name: string
  zipCode: string
  fraudSessionId: string
  idempotencyId: string
}

export const DefaultCheckoutData: CheckoutData = {
  accountInfo: {
    dateOfBirth: "",
    email: "",
    firstName: "",
    gender: "",
    lastName: "",
    phone: "",
  },
  paymentInfo: {
    finixToken: "",
    last4: "",
    name: "",
    zipCode: "",
    cardType: "",
    fraudSessionId: "",
    idempotencyId: "",
  },
  shippingInfo: {
    apt: "",
    city: "",
    country: "",
    firstName: "",
    lastName: "",
    state: "",
    streetAddress: "",
    zipCode: "",
  },
}

export interface CheckoutDataKey {
  [key: string]: any
}
