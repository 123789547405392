import { FC, memo, ReactElement } from "react"
import { useGenerateWellnessCodeMutation } from "app/services"
import { WellnessCodeRequest } from "types/authTypes"

interface IProps {
  signInValue: WellnessCodeRequest | null
}

const ResendCode: FC<IProps> = memo(({ signInValue }): ReactElement => {
  const [sendCode, { isLoading }] = useGenerateWellnessCodeMutation()

  const resendCode = () => {
    if (signInValue) {
      sendCode(signInValue)
    }
  }

  return (
    <p className="text-sm">
      Didn’t receive the code?{" "}
      <span
        className={`cursor-pointer font-bold font-body ${
          isLoading ? "text-gray" : "text-gray-black"
        }`}
        onClick={resendCode}
      >
        Resend it
      </span>
    </p>
  )
})

export default ResendCode
