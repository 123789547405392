import { ButtonHTMLAttributes, FC, ReactNode } from "react"
import { ReactComponent as EditSvg } from "assets/images/edit.svg"
import { ReactComponent as CheckSvg } from "assets/images/Check.svg"
import { ReactComponent as UncheckedSvg } from "assets/images/unchecked.svg"

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
  icon?: "edit" | "checked" | "unchecked" | null
}

const icons = {
  edit: EditSvg,
  checked: CheckSvg,
  unchecked: UncheckedSvg,
}

const TextButton: FC<Props> = ({
  children,
  icon = null,
  className,
  ...buttonProps
}) => {
  const IconComp = icon ? icons[icon] : null

  return (
    <button
      type="button"
      className={`bg-none border-0 font-semibold text-lg hover:underline ${className}`}
      {...buttonProps}
    >
      {IconComp && <IconComp className="inline-block mr-1 relative -top-1" />}
      {children}
    </button>
  )
}

export default TextButton
