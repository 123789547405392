import BaseHeader from "layouts/BaseHeader"
import BaseFooter from "layouts/BaseFooter"
import BaseLayout from "../layouts/BaseLayout"
import AccountAddress from "../features/account/addresses/AccountAddress"

const NotFound = () => {
  return (
    <>
      <BaseLayout title="Not Found">
        <p>Please check the URL and try again.</p>
      </BaseLayout>
    </>
  )
}

export default NotFound
