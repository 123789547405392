import TextInput from "components/inputs/TextInput"
import { FC } from "react"
import DateInput from "components/inputs/DateInput"
import CheckboxInput from "components/inputs/CheckboxInput"
import SelectInput from "components/inputs/SelectInput"
import NumberInput from "components/inputs/NumberInput"
import GooglePlacesInput from "components/inputs/GooglePlacesInput"

export enum FormFieldType {
  TEXT,
  SELECT,
  DATE,
  CHECKBOX,
  NUMBER,
  GOOGLE_PLACES,
}

type formFieldComponent = {
  [key in FormFieldType]: FC<any>
}

export const FORM_FIELD_COMPONENTS: formFieldComponent = {
  [FormFieldType.TEXT]: TextInput,
  [FormFieldType.SELECT]: SelectInput,
  [FormFieldType.DATE]: DateInput,
  [FormFieldType.CHECKBOX]: CheckboxInput,
  [FormFieldType.NUMBER]: NumberInput,
  [FormFieldType.GOOGLE_PLACES]: GooglePlacesInput,
}
