import { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import {
  useDeleteUserAddressMutation,
  useUpdateUserAddressMutation,
} from "app/services/userAdressApi"
import Title from "components/text/Title"
import FormFieldGroup from "components/forms/FormFieldGroup"
import {
  addressFields,
  addressYupSchema,
} from "features/account/addresses/addressForm"
import FormButtons from "components/forms/FormButtons"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { filterFormValues } from "helpers/core/formHelpers"
import { UserAddress } from "types/entities/userAddress"
import TextButton from "components/buttons/TextButton"

interface Props {
  address: UserAddress
  setEdit: Dispatch<SetStateAction<boolean>>
}

const AccountAddressEdit: FC<Props> = ({ address, setEdit }) => {
  const [updateAddress, { isLoading: isUpdating, isSuccess: hasUpdated }] =
    useUpdateUserAddressMutation()

  const [removeAddress, { isSuccess: hasRemoved }] =
    useDeleteUserAddressMutation()

  const [showConfirmation, setShowConfirmation] = useState(false)

  const {
    handleSubmit,
    setValue,
    control,
    formState: { isDirty },
  } = useForm<any>({
    resolver: yupResolver(addressYupSchema),
    values: filterFormValues(addressFields(), address),
  })

  const onSubmit = (data: Partial<UserAddress>) => {
    updateAddress({ id: address.id, ...data })
  }

  const onRemove = () => {
    removeAddress(Number(address.id))
  }

  useEffect(() => {
    if (hasUpdated || hasRemoved) {
      setEdit(false)
    }
  }, [hasUpdated, hasRemoved])

  return (
    <div className="max-w-xl mx-auto items-center justify-center">
      <Title>Address</Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormFieldGroup fields={addressFields(setValue)} control={control} />
        <FormButtons
          onCancel={() => setEdit(false)}
          isSubmitDisabled={!isDirty || isUpdating}
        />
      </form>
      <div className="flex justify-center mt-4">
        <TextButton onClick={() => setShowConfirmation(true)}>
          Remove address
        </TextButton>

        {showConfirmation && (
          <div className="flex space-x-4 items-center ml-32">
            <TextButton icon="checked" onClick={onRemove}>
              Yes
            </TextButton>

            <TextButton
              icon="unchecked"
              onClick={() => setShowConfirmation(false)}
            >
              No
            </TextButton>
          </div>
        )}
      </div>
    </div>
  )
}

export default AccountAddressEdit
