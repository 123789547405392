import { Dispatch, FC, SetStateAction, useEffect } from "react"
import Title from "components/text/Title"
import {
  addressFields,
  addressYupSchema,
} from "features/account/addresses/addressForm"
import FormFieldGroup from "components/forms/FormFieldGroup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import FormButtons from "components/forms/FormButtons"
import {
  useCreateUserAddressMutation,
  useGetUserAddressesQuery,
} from "app/services/userAdressApi"
import { UserAddress } from "types/entities/userAddress"
import { getMyId } from "helpers/authHelpers"

interface Props {
  setCreate: Dispatch<SetStateAction<boolean>>
}

const AccountAddressCreate: FC<Props> = ({ setCreate }) => {
  const [createAddress, { isLoading: isCreating, isSuccess }] =
    useCreateUserAddressMutation()

  const userId = getMyId()

  const {
    handleSubmit,
    setValue,
    control,
    formState: { isDirty },
  } = useForm<any>({
    resolver: yupResolver(addressYupSchema),
  })

  const onSubmit = (data: UserAddress) => {
    const payload = {
      ...data,
      user: `/users/${userId}`,
    }

    createAddress(payload)
  }

  useEffect(() => {
    if (isSuccess) {
      setCreate(false)
    }
  }, [isSuccess])

  return (
    <div className="max-w-xl mx-auto items-center justify-center">
      <Title>Address</Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormFieldGroup fields={addressFields(setValue)} control={control} />
        <FormButtons
          onCancel={() => setCreate(false)}
          isSubmitDisabled={!isDirty || isCreating}
        />
      </form>
    </div>
  )
}

export default AccountAddressCreate
