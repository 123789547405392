import AccountDetailsEdit from "features/account/update/AccountDetailsEdit"
import AccountDetailsView from "features/account/update/AccountDetailsView"
import { FC, useState } from "react"
import { User } from "types/entities/userTypes"

interface Props {
  account?: User
}

const AccountDetails: FC<Props> = ({ account }) => {
  const [edit, setEdit] = useState(false)

  if (!account) {
    return null
  }

  return (
    <div className="mt-16">
      {!edit ? (
        <AccountDetailsView setEdit={setEdit} account={account} />
      ) : (
        <AccountDetailsEdit setEdit={setEdit} account={account} />
      )}
    </div>
  )
}

export default AccountDetails
