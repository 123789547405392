import { FC, ReactElement, useCallback, useState, useEffect } from "react"
import LoginConfirmationContent from "features/login/LoginConfirmationContent"
import LoginContent from "features/login/LoginContent"
import { User } from "types/entities/userTypes"
import useLogin from "hooks/auth/useLogin"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { selectRedirectAfterLogin } from "features/auth/authSlice"
import { ReactComponent as BgDecorativeSvg } from "assets/images/bg-decorative.svg"
import PoweredBy from "components/blocks/PoweredBy"
import useSignInPopup from "features/auth/useSignInPopup"
import { WellnessCodeRequest } from "types/authTypes"

interface IProps {
  inPopup: boolean
}

const Login: FC<IProps> = ({ inPopup = false }): ReactElement => {
  const [userId, setUserId] = useState<string>("")
  const [signInValue, setSignInValue] = useState<WellnessCodeRequest | null>(
    null,
  )

  const { hideSignIn, isSignInOpen } = useSignInPopup()
  const login = useLogin()
  const navigate = useNavigate()
  const redirectAfterLogin = useSelector(selectRedirectAfterLogin)

  useEffect(() => {
    if (!isSignInOpen) {
      cancel()
    }
  }, [isSignInOpen])

  const cancel = useCallback((): void => {
    setUserId("")
    setSignInValue(null)
  }, [])

  const onSignIn = useCallback(
    (token: string, user: User): void => {
      if (userId) {
        login(token, user)

        if (inPopup) {
          hideSignIn()
        }

        if (redirectAfterLogin) {
          navigate(redirectAfterLogin.path, { state: redirectAfterLogin.state })
        } else {
          navigate("/account")
        }
      }
    },
    [userId],
  )

  const displayLoginContent = (
    userId: string | undefined,
    inPopup: boolean,
  ): ReactElement => {
    if (userId) {
      return (
        <LoginConfirmationContent
          signInValue={signInValue}
          userId={userId}
          onSuccess={onSignIn}
          cancel={cancel}
          inPopup={inPopup}
        />
      )
    }

    return (
      <LoginContent
        setUserId={setUserId}
        setSignInValue={setSignInValue}
        inPopup={inPopup}
      />
    )
  }

  return (
    <main
      className={
        inPopup
          ? "md:w-[450px] flex flex-col items-center gap-1.31rem h-screen sm:h-auto"
          : "flex items-start justify-center min-h-screen pt-24"
      }
    >
      {!inPopup ? (
        <section className="absolute -top-36 overflow-hidden">
          <BgDecorativeSvg />
        </section>
      ) : null}
      <div className="flex flex-col items-center justify-center gap-1.31rem relative sm:min-30rem mx-8 z-10">
        {displayLoginContent(userId, inPopup)}
      </div>
      {inPopup ? <PoweredBy /> : null}
    </main>
  )
}

export default Login
