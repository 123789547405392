import { FC, ReactNode } from "react"

interface Props {
  name: string
  children?: ReactNode
}

const TextField: FC<Props> = ({ name, children }) => {
  return (
    <div className="mt-8">
      <div className="font-bold text-jacarta text-base">{name}</div>
      <div className="mt-2 text-jacarta">{children ?? "-"}</div>
    </div>
  )
}

export default TextField
