import BaseLayout from "layouts/BaseLayout"
import AccountMenu from "features/account/menu/AccountMenu"

const AccountHome = () => {
  return (
    <BaseLayout title="My Account" hasBreadcrumbs={false}>
      <AccountMenu />
    </BaseLayout>
  )
}

export default AccountHome
