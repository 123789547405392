import { Link } from "react-router-dom"
import { FC } from "react"
import { Breadcrumb } from "types/navigationTypes"

interface Props {
  crumbs: Breadcrumb[]
}

const Breadcrumbs: FC<Props> = ({ crumbs }) => {
  return (
    <nav aria-label="breadcrumb" className="flex justify-center -mt-6 mb-12">
      <ol className="flex space-x-2 text-sm text-black">
        {crumbs.map((crumb, index) => (
          <li key={index}>
            {index + 1 < crumbs.length && crumb.link ? (
              <>
                <Link
                  to={crumb.link}
                  className="text-gray-granite hover:text-black"
                >
                  {crumb.name}
                </Link>
                <span className="mx-2 text-gray-granite">&gt;</span>
              </>
            ) : (
              crumb.name
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}

export default Breadcrumbs
