import { format as dateFnsFormat } from "date-fns"

export const DATE_FORMAT_DEFAULT = "MM/dd/yyyy"
export const DATE_FORMAT_FULL_MONTH = "LLL dd, yyyy"

export const formatDate = (
  date: Date | string | null | undefined,
  format: string = DATE_FORMAT_DEFAULT,
): string | null => {
  if (!date) {
    return null
  }

  return dateFnsFormat(date, format)
}

export const formatDateToYYYYMMDD = (date: Date): string => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, "0") // Add leading 0 for months
  const day = String(date.getDate()).padStart(2, "0") // Add leading 0 for days
  return `${year}-${month}-${day}`
}
