import { FetchBaseQueryError } from "@reduxjs/toolkit/query"

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export function isFetchBaseQueryError(
  error: unknown,
): error is FetchBaseQueryError {
  return typeof error === "object" && error != null && "status" in error
}

/**
 * Type predicate to narrow an unknown error to an object with a string 'data' property
 */
export function isErrorWithData(
  error: unknown,
): error is { data: { detail: string } } {
  return (
    typeof error === "object" &&
    error != null &&
    "data" in error &&
    typeof (error as any).data === "object"
  )
}
