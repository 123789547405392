import Popup from "components/popups/Popup"
import { FC } from "react"
import TextField from "components/fields/TextField"
import { Transaction } from "types/entities/transactionTypes"
import { DATE_FORMAT_FULL_MONTH, formatDate } from "utils/dateTimeUtils"
import { formatCurrency } from "utils/currencyUtils"

interface Props {
  transaction: Transaction
  onClose: () => void
}

const ReceiptPopup: FC<Props> = ({ transaction, onClose }) => {
  return (
    <Popup isOpen={true} onClose={onClose} verticalCenter={false}>
      <div className="text-left">
        <div className="w-[680px] -mt-10 text-left">
          <div className="text-3xl font-semibold mb-1">View Receipt</div>
        </div>
        <div className="text-gray-slate">{transaction.subscription?.title}</div>
        <div className="mt-10 mb-8 text-2xl font-semibold">
          {transaction.subscription?.title}
        </div>
        <TextField name={transaction.physician.name_display}>
          <>
            {transaction.physician.address_line1}
            <br />
            {transaction.physician.address_city},{" "}
            {transaction.physician.address_region}{" "}
            {transaction.physician.address_postal_code}
          </>
        </TextField>
        <div className="grid grid-cols-2 gap-y-4">
          <TextField name="Date paid">
            {formatDate(transaction.timestamp, DATE_FORMAT_FULL_MONTH)}
          </TextField>
          <TextField name="Amount paid">
            {formatCurrency(transaction.amt)}
          </TextField>
        </div>
        <TextField name="Details">
          <>{transaction.subscription?.description}</>
        </TextField>
      </div>
    </Popup>
  )
}

export default ReceiptPopup
