import { accountMenuItems } from "features/account/menu/menuItems"
import { Link } from "react-router-dom"
import useAuth from "hooks/auth/useAuth"

const AccountMenu = () => {
  const user = useAuth()

  return (
    <div className="grid md:grid-cols-2 grid-cols-1 gap-8">
      {accountMenuItems.map((item, index) => {
        // TODO remove when we will have the ability to create payment method
        if (
          item.link === "/account/payment-methods" &&
          !user?.credit_authorization
        ) {
          return null
        }

        return (
          <Link key={index} to={item.link}>
            <div className="flex space-x-4 border p-6 rounded-md hover:bg-gray-100 transition cursor-pointer">
              <div>
                <item.icon />
              </div>
              <div className="pt-1">
                <p className="mb-2 text-xl font-medium">{item.title}</p>
                <p className="text-gray">{item.description}</p>
              </div>
            </div>
          </Link>
        )
      })}
    </div>
  )
}

export default AccountMenu
