import { buildRest } from "app/services/restEndpoints"
import { api } from "app/services/api"
import { UserAddress } from "types/entities/userAddress"

export const userAddressesApi = api.injectEndpoints({
  endpoints: (build) => {
    const rest = buildRest<UserAddress>(build, "user-address", "UserAddress")

    return {
      getUserAddress: rest.one,
      getUserAddresses: rest.list,
      createUserAddress: rest.create,
      updateUserAddress: rest.update,
      deleteUserAddress: rest.delete,
    }
  },
})

export const {
  useGetUserAddressQuery,
  useGetUserAddressesQuery,
  useCreateUserAddressMutation,
  useUpdateUserAddressMutation,
  useDeleteUserAddressMutation,
} = userAddressesApi
