import { useGetTransactionsQuery } from "app/services/transactionsApi"
import { getMyId } from "helpers/authHelpers"
import { Transaction, TransactionStatus } from "types/entities/transactionTypes"
import { useGetUserSubscriptionsQuery } from "app/services/userSubscriptionsApi"
import { useEffect, useState } from "react"

const useGetMyTransactionsWithSubscription = () => {
  const { data: trans } = useGetTransactionsQuery({
    user: getMyId(),
    ack: TransactionStatus.SUCCESS,
    "order[timestamp]": "desc",
  })
  const { data: subs } = useGetUserSubscriptionsQuery({
    user: getMyId(),
  })
  const [result, setResult] = useState<Transaction[]>()

  useEffect(() => {
    if (trans && subs) {
      const data = trans.items.map((tran) => {
        let subscription
        if (tran.subscriptions[0]) {
          subscription = subs.items.find(
            (sub) => sub.subscription?.id === tran.subscriptions[0],
          )?.subscription
        }
        return {
          ...tran,
          subscription: subscription,
        }
      })

      setResult(data)
    }
  }, [trans, subs])

  return {
    data: result,
    isLoading: result === undefined,
  }
}

export default useGetMyTransactionsWithSubscription
