import { FC, memo, ReactElement } from "react"
import { useNavigate } from "react-router-dom"

interface IProps {
  isLogoVisible: boolean
  body: string
  description: ReactElement
}

const BrandHeader: FC<IProps> = memo(
  ({ body, description, isLogoVisible = true }): ReactElement => {
    const navigate = useNavigate()

    return (
      <>
        <section className="flex flex-col items-center z-10 gap-1.31rem">
          {isLogoVisible ? (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="182"
                height="48"
                viewBox="0 0 182 48"
                fill="none"
                onClick={() => navigate("/")}
              >
                <path
                  d="M0.25 47.5827V47.702H15.4252V47.5827C13.0401 46.9864 11.6388 45.1678 11.6388 42.1864V9.9877C11.6388 7.00633 13.0401 5.1877 15.4252 4.59142V4.47217H0.25V4.59142C2.63509 5.1877 4.03635 7.00633 4.03635 9.9877V42.1864C4.03635 45.1678 2.63509 46.9864 0.25 47.5827ZM18.7047 47.702C33.1345 47.2846 41.9295 39.3542 41.691 25.3715C41.4525 11.4784 32.0015 4.50198 18.7047 4.47217V4.59142C26.9929 5.12807 32.4189 12.4622 32.6276 26.1765C32.8363 39.6225 27.9767 46.9864 18.7047 47.5827V47.702Z"
                  fill="#272522"
                />
                <path
                  d="M44.1582 47.5828V47.702H59.6911V47.5828C57.0079 46.9865 55.2787 45.1977 55.2787 42.2163V17.4412C51.6116 18.8424 48.1532 19.7964 43.8899 20.6909V20.8101C46.5731 21.2573 47.9744 22.7778 47.9744 26.4151V42.2163C47.9744 45.1977 46.5433 46.9865 44.1582 47.5828ZM57.0675 25.312L57.1868 25.3418C58.111 23.225 58.916 22.5095 60.0787 22.5095C62.0166 22.5095 64.3719 25.2225 64.7296 29.3666L64.8489 29.3964C66.1905 25.5207 67.4427 22.4499 68.7247 19.2896C67.4725 18.3654 65.7731 17.5008 63.9843 17.5008C60.9433 17.5008 58.439 20.0648 57.0675 25.312Z"
                  fill="#272522"
                />
                <path
                  d="M71.6528 12.5517L87.5435 48.0001H87.6627L94.1919 30.0821L94.0726 30.0225C91.8962 34.1964 89.7795 34.2858 88.1696 30.3504L80.9546 12.7604C79.1062 8.2585 77.8838 5.03863 82.1472 4.59142V4.47217H65.3323V4.59142C68.1049 5.06844 69.7447 8.28832 71.6528 12.5517ZM86.7683 4.59142C90.4354 5.06844 92.3136 8.19888 93.9832 12.5517L107.489 48.0001H107.608L114.018 29.3666L113.899 29.3069C111.722 33.4809 109.844 33.4809 108.383 29.0684L102.987 12.7604C101.437 8.07962 100.214 5.24732 103.881 4.59142V4.47217H86.7683V4.59142ZM111.186 4.59142C116.463 5.12807 119.861 10.3753 116.045 22.4498L116.165 22.5094C119.891 13.0585 122.187 5.6349 126.48 4.59142V4.47217H111.186V4.59142Z"
                  fill="#272522"
                />
                <path
                  d="M136.332 47.9401C144.621 47.9997 148.258 42.6631 149.003 40.0693L148.884 40.0395C147.572 43.1699 143.756 46.9264 138.896 46.9264C132.576 46.9264 129.445 40.6357 129.386 32.0792C129.356 24.596 131.89 18.4246 135.229 17.7687V17.6494C127.06 17.9476 121.187 24.6258 121.396 33.7488C121.604 42.7227 127.686 47.8805 136.332 47.9401ZM132.367 31.3339L149.57 31.1848C149.361 23.6718 144.949 18.0072 137.913 17.6494V17.7687C140.149 18.6034 141.788 21.7339 141.878 25.6097C141.937 29.396 140.447 30.6482 132.367 31.2146V31.3339Z"
                  fill="#272522"
                />
                <path
                  d="M151.203 47.5826V47.7018H165.991V47.5826C163.457 46.9863 162.175 45.0782 162.175 42.2161V0C158.537 1.37143 155.228 2.32546 150.935 3.24969V3.36894C153.618 3.81615 155.019 5.33664 155.019 8.97391V42.2161C155.019 45.1975 153.678 46.9863 151.203 47.5826Z"
                  fill="#272522"
                />
                <path
                  d="M166.808 47.5826V47.7018H181.596V47.5826C179.062 46.9863 177.78 45.0782 177.78 42.2161V0C174.143 1.37143 170.833 2.32546 166.54 3.24969V3.36894C169.223 3.81615 170.625 5.33664 170.625 8.97391V42.2161C170.625 45.1975 169.283 46.9863 166.808 47.5826Z"
                  fill="#272522"
                />
              </svg>
              <article className="text-center z-10">
                <p className="text-base tracking-wide	font-medium accent-gray-charcoal">
                  Your digital prescription for a
                </p>
                <p className="text-2xl font-bold bg-gradient-to-r from-bronze to-silver-pale text-transparent bg-clip-text font-header mt-1.5">
                  healthier life
                  <span
                    className="text-3xl font-header font-bold"
                    style={{ color: "#344054" }}
                  >
                    .
                  </span>
                </p>
              </article>
            </>
          ) : null}
        </section>
        <p className="text-3xl text-center font-semibold font-header accent-black-primary z-10">
          {body}
        </p>
        {description}
      </>
    )
  },
)

export default BrandHeader
