import BaseLayout from "layouts/BaseLayout"
import PaymentMethodEdit from "features/account/payment-methods/PaymentMethodEdit"

const AccountPaymentMethodEdit = () => {
  return (
    <BaseLayout title="Payment methods" maxWidth="max-w-xl">
      <PaymentMethodEdit isNew={false} />
    </BaseLayout>
  )
}

export default AccountPaymentMethodEdit
