import BaseHeader from "layouts/BaseHeader"
import BaseFooter from "layouts/BaseFooter"

const Home = () => {
  return (
    <>
      <BaseHeader />
      <main className="flex flex-col items-center justify-center py-10 px-4">
        This is the DrWell home page.
      </main>

      <BaseFooter />
    </>
  )
}

export default Home
