import { useAppSelector } from "app/hooks"
import { selectCurrentUser } from "features/auth/authSlice"

const useAuth = () => {
  const user = useAppSelector(selectCurrentUser)

  return user
}

export default useAuth
