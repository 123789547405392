import { FC, ReactNode } from "react"

interface Props {
  children?: ReactNode
}

const InputLabel: FC<Props> = ({ children }) => {
  if (!children) return null

  return (
    <div className="mb-3 text-base text-jacarta font-semibold">{children}</div>
  )
}

export default InputLabel
