import React, { useState } from "react"
import Button from "components/buttons/Button"
import { CheckoutData } from "features/checkout/CheckoutSteps"
import PaymentForm from "features/checkout/steps/forms/PaymentForm"

interface Props {
  checkoutData: CheckoutData
  onSubmit: (data: object) => void
}

const PaymentInfo: React.FC<Props> = ({ onSubmit, checkoutData }) => {
  const [isChange, setIsChange] = useState(false)

  if (isChange) {
    return (
      <div>
        <div className="flex justify-between items-center mb-6">
          <div className="text-lg font-semibold">Payment information</div>
        </div>
        <PaymentForm
          checkoutData={checkoutData}
          onSubmit={(data) => {
            onSubmit(data)
            setIsChange(false)
          }}
          buttonLabel="Save changes"
        />
        <div className="mt-5">
          <Button size="s" color="white" onClick={() => setIsChange(false)}>
            Cancel
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-row gap-6 justify-between w-full">
        <div className="text-lg font-semibold flex-1">Payment information</div>
        <div className="flex items-center">
          <button
            className="text-md font-semibold text-gray-500"
            onClick={() => {
              setIsChange(true)
            }}
          >
            Change
          </button>
        </div>
      </div>
      <div className="flex flex-col gap-4 w-full">
        <div className="flex flex-col gap-2 w-full">
          <div className="bg-white rounded-md w-full">
            <div className="text-auroMetalSaurus mb-2 font-medium text-base">
              Card
            </div>
            <div className="text-md">
              <div>
                {checkoutData.paymentInfo.cardType} **** **** ****{" "}
                {checkoutData.paymentInfo.last4}
              </div>
              <div>{checkoutData.paymentInfo.name}</div>
              <div>CVC ***</div>
              <div>ZIP {checkoutData.paymentInfo.zipCode}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentInfo
