export const formatCurrency = (
  currency: number | null | undefined,
): string | null => {
  if (!currency) {
    return null
  }

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  })

  return formatter.format(currency)
}
